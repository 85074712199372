import * as React from 'react';
import { useState, useEffect } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { motion, AnimatePresence } from 'framer-motion';
import { cn } from "../lib/utils";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Mail, LogOut, Users, Origami, SettingsIcon, LayoutDashboard, Play, Pause, MailWarning } from 'lucide-react';
import { ScrollArea } from "../components/ui/scroll-area";
import { Button } from "../components/ui/button";
import { useToast } from "../components/ui/use-toast";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../components/ui/alert-dialog";
import { createRoot } from 'react-dom/client';

const GET_CAMPAIGN = gql`
  query GetCampaign($user_id: Int!) {
    getCampaign(user_id: $user_id) {
      id
      status
      email_preference
    }
  }
`;

const TOGGLE_CAMPAIGN = gql`
  mutation ToggleCampaign($user_id: Int!, $active: Boolean!, $useWarmup: Boolean) {
    toggleCampaign(user_id: $user_id, active: $active, useWarmup: $useWarmup) {
      id
      status
      email_warmup
      email_preference
    }
  }
`;

interface SidebarProps {
  userId: string | null;
  onLogout: () => void;
}

const showEmailWarmupPopup = () => {
  return new Promise<boolean>((resolve) => {
    const WarmupPopup = () => {
      const [isOpen, setIsOpen] = useState(true);

      const handleConfirm = () => {
        setIsOpen(false);
        resolve(true);
      };

      const handleCancel = () => {
        setIsOpen(false);
        resolve(false);
      };

      return (
        <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
          <AlertDialogContent className="sm:max-w-[425px] bg-white">
            <AlertDialogHeader>
              <div className="flex items-center space-x-2 mb-2">
                <MailWarning className="h-6 w-6 text-blue-500" />
                <AlertDialogTitle className="text-xl font-semibold">Enable Email Warmup?</AlertDialogTitle>
              </div>
              <AlertDialogDescription className="text-gray-600">
                <p className="mb-3">
                  Email Warmup is recommended for high-volume sending. It helps prevent your emails from being marked as spam.
                </p>
                <ul className="list-disc list-inside space-y-1 mb-3">
                  <li>Starts at 10 emails per day</li>
                  <li>Gradually increases over 6 days</li>
                  <li>Reaches full volume on day 7</li>
                  <li>Improves email deliverability</li>
                </ul>
                <p className="font-medium">Would you like to enable Email Warmup for this campaign?</p>
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter className="sm:justify-start">
              <AlertDialogCancel asChild>
                <Button variant="outline" onClick={handleCancel}>No, start normally</Button>
              </AlertDialogCancel>
              <AlertDialogAction asChild>
                <Button className="bg-blue-500 text-white hover:bg-blue-600" onClick={handleConfirm}>Yes, enable Warmup</Button>
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      );
    };

    // Create a container for the popup
    const popupContainer = document.createElement('div');
    document.body.appendChild(popupContainer);

    // Use createRoot instead of ReactDOM.render
    const root = createRoot(popupContainer);
    root.render(<WarmupPopup />);

    // Cleanup function
    const cleanup = () => {
      root.unmount();
      popupContainer.remove();
    };

    // Ensure cleanup happens even if the promise is not resolved
    setTimeout(() => {
      if (popupContainer.parentNode) {
        cleanup();
        resolve(false);
      }
    }, 60000); // Timeout after 60 seconds
  });
};

const Sidebar: React.FC<SidebarProps> = ({ userId, onLogout }) => {
  const [isHovered, setIsHovered] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { toast } = useToast();

  const { data: campaignData, loading: campaignLoading, refetch: refetchCampaign } = useQuery(GET_CAMPAIGN, {
    variables: { user_id: userId ? parseInt(userId) : null },
    skip: !userId,
    fetchPolicy: 'network-only',
  });

  const [toggleCampaignMutation] = useMutation(TOGGLE_CAMPAIGN, {
    refetchQueries: [{ query: GET_CAMPAIGN, variables: { user_id: userId } }]
  });

  const [campaign, setCampaign] = useState<any>(null);

  useEffect(() => {
    if (campaignData?.getCampaign) {
      setCampaign(campaignData.getCampaign);
    }
  }, [campaignData]);

  const toggleCampaign = async () => {
    if (!userId) return;
  
    try {
      if (campaign?.status !== 'active') {
        // Campaign is being activated
        let useWarmup = false;
        if (campaign?.email_preference === 'high' || campaign?.email_preference === 'ultra_high') {
          useWarmup = await showEmailWarmupPopup();
        }
        
        const result = await toggleCampaignMutation({
          variables: {
            user_id: parseInt(userId),
            active: true,
            useWarmup: useWarmup
          }
        });
  
        if (result.data?.toggleCampaign) {
          setCampaign({
            ...campaign,
            status: result.data.toggleCampaign.status,
            email_warmup: useWarmup
          });
  
          toast({ 
            title: "Campaign activated",
            description: useWarmup ? "Your campaign is now running with Email Warmup." : "Your campaign is now running.",
            className: "bg-white",
          });
        }
      } else {
        // Campaign is being deactivated
        const result = await toggleCampaignMutation({
          variables: {
            user_id: parseInt(userId),
            active: false
          }
        });
  
        if (result.data?.toggleCampaign) {
          setCampaign({
            ...campaign,
            status: result.data.toggleCampaign.status
          });
  
          toast({ 
            title: "Campaign paused",
            description: "Your campaign has been paused.",
            className: "bg-white",
          });
        }
      }
    } catch (error) {
      console.error('Error in toggleCampaign:', error);
  
      if (error instanceof Error) {
        if (error.message.includes('INSUFFICIENT_CONTACTS')) {
          toast({
            title: "Cannot start campaign",
            description: "You need at least 1,000 contacts in your target audience to start the campaign.",
            variant: "destructive",
            className: "bg-white",
          });
        } else if (error.message.includes('EMAIL_NOT_CONNECTED')) {
          toast({
            title: "Email Not Connected",
            description: "Please connect your email in the settings tab before starting a campaign.",
            variant: "destructive",
            className: "bg-white",
          });
          navigate('/settings');
        } else if (error.message.includes('EMAIL_NOT_INTEGRATED')) {
          toast({
            title: "Email Not Integrated",
            description: "Please set up your email in the settings tab before starting a campaign.",
            variant: "destructive",
            className: "bg-white",
          });
          navigate('/settings');
        } else {
          toast({
            title: "Error starting campaign",
            description: error.message,
            variant: "destructive",
            className: "bg-white",
          });
        }
      } else {
        toast({
          title: "Error toggling campaign",
          description: "An unknown error occurred",
          variant: "destructive",
          className: "bg-white",
        });
      }
    }
  };

  const menuItems = [
    { to: "/dashboard", icon: LayoutDashboard, label: "Dashboard" },
    { to: "/email-campaign", icon: Mail, label: "Email Campaign" },
    { to: "/target-companies", icon: Users, label: "Target Companies" },
    { to: "/settings", icon: SettingsIcon, label: "Settings" },
  ];

  return (
    <motion.aside
      initial={{ width: 80 }}
      animate={{ width: isHovered ? 240 : 80 }}
      transition={{ duration: 0.3 }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="flex flex-col h-screen bg-white border-r border-gray-200"
    >
      <div className="flex items-center justify-center h-16 border-b border-gray-200 px-4">
        <Origami className={`h-8 w-8 text-blue-600 flex-shrink-0 ${!isHovered && 'mx-auto'}`} />
        <AnimatePresence>
          {isHovered && (
            <motion.span
              initial={{ opacity: 0, width: 0 }}
              animate={{ opacity: 1, width: "auto" }}
              exit={{ opacity: 0, width: 0 }}
              transition={{ duration: 0.2 }}
              className="ml-2 text-lg font-semibold text-gray-800 whitespace-nowrap overflow-hidden"
            >
              Lance's Job Finder
            </motion.span>
          )}
        </AnimatePresence>
      </div>

      <ScrollArea className="flex-grow py-4">
        <nav>
          {menuItems.map((item) => (
            <Link
              key={item.to}
              to={item.to}
              className={cn(
                "flex items-center px-4 py-3 mb-1 text-gray-600",
                "hover:bg-gray-100 hover:text-gray-900 rounded-lg mx-2",
                "transition-all duration-200",
                location.pathname === item.to && "bg-blue-50 text-blue-600"
              )}
            >
              <div className={cn(
                "flex items-center justify-center w-5 flex-shrink-0",
                !isHovered && "w-full"
              )}>
                <item.icon className="h-5 w-5" />
              </div>
              <AnimatePresence>
                {isHovered && (
                  <motion.span
                    initial={{ opacity: 0, width: 0 }}
                    animate={{ opacity: 1, width: "auto" }}
                    exit={{ opacity: 0, width: 0 }}
                    transition={{ duration: 0.2 }}
                    className="ml-3 whitespace-nowrap overflow-hidden"
                  >
                    {item.label}
                  </motion.span>
                )}
              </AnimatePresence>
            </Link>
          ))}
        </nav>
      </ScrollArea>

      <div className="border-t border-gray-200 p-4 space-y-2">
        <Button 
          onClick={toggleCampaign}
          variant="outline"
          className={cn(
            "w-full transition-all duration-200",
            campaign?.status === 'active'
              ? "bg-red-50 text-red-600 hover:bg-red-100"
              : "bg-green-50 text-green-600 hover:bg-green-100"
          )}
        >
          <div className={cn(
            "flex items-center justify-center w-5 flex-shrink-0",
            !isHovered && "w-full"
          )}>
            {campaign?.status === 'active' ? (
              <Pause className="h-5 w-5" />
            ) : (
              <Play className="h-5 w-5" />
            )}
          </div>
          <AnimatePresence>
            {isHovered && (
              <motion.span
                initial={{ opacity: 0, width: 0 }}
                animate={{ opacity: 1, width: "auto" }}
                exit={{ opacity: 0, width: 0 }}
                transition={{ duration: 0.2 }}
                className="ml-2 whitespace-nowrap overflow-hidden"
              >
                {campaign?.status === 'active' ? 'Pause Campaign' : 'Start Campaign'}
              </motion.span>
            )}
          </AnimatePresence>
        </Button>

        <Button 
          onClick={onLogout}
          variant="ghost"
          className="w-full text-gray-600 hover:bg-gray-100 hover:text-gray-900"
        >
          <div className={cn(
            "flex items-center justify-center w-5 flex-shrink-0",
            !isHovered && "w-full"
          )}>
            <LogOut className="h-5 w-5" />
          </div>
          <AnimatePresence>
            {isHovered && (
              <motion.span
                initial={{ opacity: 0, width: 0 }}
                animate={{ opacity: 1, width: "auto" }}
                exit={{ opacity: 0, width: 0 }}
                transition={{ duration: 0.2 }}
                className="ml-2 whitespace-nowrap overflow-hidden"
              >
                Logout
              </motion.span>
            )}
          </AnimatePresence>
        </Button>
      </div>
    </motion.aside>
  );
};

export default Sidebar;