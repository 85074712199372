import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { DateTime } from 'luxon';

// Shadcn Imports
import { Button } from "../components/ui/button";
import { Textarea } from "../components/ui/textarea";
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from "../components/ui/card";
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from "../components/ui/form";
import { useToast } from "../components/ui/use-toast";
import { Input } from "../components/ui/input";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../components/ui/dropdown-menu";

// Lucide Icons
import { Variable, Edit, FileText, Eye, MessageSquare, Save, Mail } from 'lucide-react';

// GraphQL Queries and Mutations
const UPDATE_CAMPAIGN = gql`
  mutation UpdateCampaign($user_id: Int!, $input: CampaignInput!) {
    updateCampaign(user_id: $user_id, input: $input) {
      id
      subject
      message
      country
      state
      city
      industry
      company_size
      email_preference
    }
  }
`;

const TOGGLE_CAMPAIGN = gql`
  mutation ToggleCampaign($user_id: Int!, $active: Boolean!) {
    toggleCampaign(user_id: $user_id, active: $active) {
      id
      status
    }
  }
`;

const GET_CAMPAIGN = gql`
  query GetCampaign($user_id: Int!) {
    getCampaign(user_id: $user_id) {
      id
      status
      subject
      message
      country
      state
      city
      industry
      company_size
      email_preference
    }
  }
`;

// Form schema
const formSchema = z.object({
  subject: z.string().min(1, { message: "Subject is required." })
    .refine(
      (value) => {
        const placeholders = value.match(/{{[^{}]+}}/g) || [];
        return placeholders.every(p => ['{{first_name}}', '{{last_name}}', '{{company_name}}', '{{country}}', '{{state}}', '{{city}}', '{{time_of_day}}', '{{day_of_week}}'].includes(p));
      },
      {
        message: "Invalid placeholders in subject. Only {{first_name}}, {{last_name}}, {{company_name}}, {{country}}, {{state}}, {{city}}, {{time_of_day}}, and {{day_of_week}} are allowed."
      }
    ),
  message: z.string().min(10, { message: "Message must be at least 10 characters." })
    .refine(
      (value) => {
        const placeholders = value.match(/{{[^{}]+}}/g) || [];
        return placeholders.every(p => ['{{first_name}}', '{{last_name}}', '{{company_name}}', '{{country}}', '{{state}}', '{{city}}', '{{time_of_day}}', '{{day_of_week}}'].includes(p));
      },
      {
        message: "Invalid placeholders. Only {{first_name}}, {{last_name}}, {{company_name}}, {{country}}, {{state}}, {{city}}, {{time_of_day}}, and {{day_of_week}} are allowed."
      }
    ),
});

// Email templates
const TEMPLATE_1 = `Subject: {{first_name}} & [YOUR NAME] - Interested in joining {{company_name}}

Hi {{first_name}},

I hope you're doing well! I'm emailing you because I'm interested in working at {{company_name}}. I found your profile on LinkedIn and thought I'd be a good fit.

I'm amazing at [INSERT WHAT YOU'RE GOOD AT] and would like to help out however I can.

I would be grateful for the opportunity to have a phone call about how my skills and experience could align with {{company_name}}. Would you be available for a brief conversation?

Thank you,
[YOUR NAME]`;

const TEMPLATE_2 = `Subject: Interested in joining {{company_name}}

Hi {{first_name}},

I hope you're having a great week! I want to join {{company_name}} as a [DESIRED JOB] because I love what you're doing.

My background is in [BACKGROUND] and I'm extremely good at [SKILLS]. One of my proudest achievements during [JOB or SCHOOL] was [ACHIEVEMENT].

I believe my talents would translate well to {{company_name}}. Would it be possible to schedule a call or meeting? I'd greatly appreciate the opportunity to speak with you!

Best regards,
[YOUR NAME]`;

const TEMPLATE_3 = `Subject: Sales Role Application for {{company_name}}

Hi {{first_name}},

I hope your {{day_of_week}} is going well! I'm [INSERT YOUR NAME], and I'm reaching out because I'm excited about the possibility of joining your sales team at {{company_name}}.

I've followed your company's growth and am confident I can contribute to your success. Here's why:
1. I have a proven track record of [INSERT SPECIFIC ACHIEVEMENT, e.g., "increasing sales by 40% in just 6 months at my current role"]
2. My expertise in [INSERT RELEVANT SKILL, e.g., "B2B sales"] aligns well with your market
3. I've consistently [INSERT UNIQUE STRENGTH, e.g., "exceeded quotas by leveraging data-driven strategies"]

I'd love to have a quick 20-minute call to discuss how I can add value to your team. Are you free next week?

Best regards,
[INSERT YOUR NAME]`;

const TEMPLATE_4 = `Subject: Internship Opportunity at {{company_name}}

Hi {{first_name}},

I hope you've had a great {{day_of_week}} so far! My name is [INSERT YOUR NAME], and I'm a [INSERT YOUR YEAR AND MAJOR] student at [INSERT YOUR UNIVERSITY]. I'm reaching out because I'm extremely interested in pursuing an internship opportunity with {{company_name}}.

I'm excited about the possibility of contributing to your team. Here's why I believe I'd be a great fit:
1. I have experience in [INSERT RELEVANT PROJECT OR COURSEWORK, e.g., "developing machine learning models for predictive analytics"]
2. My skills in [INSERT RELEVANT SKILLS, e.g., "Python programming and data visualization"] align well with your company's needs
3. I've demonstrated [INSERT PERSONAL QUALITY, e.g., "strong leadership and teamwork skills"] through my involvement in [INSERT EXTRACURRICULAR ACTIVITY OR ACADEMIC ACHIEVEMENT]

I would be grateful for the opportunity to discuss how my skills and enthusiasm could contribute to {{company_name}}. Are you available for a quick call next week to explore potential internship opportunities?

Thank you for your time and consideration. I look forward to the possibility of speaking with you.

Best regards,
[INSERT YOUR NAME]`;

interface CampaignSetupProps {
  userId: string | null;
}

const EmailCampaign = ({ userId }) => {
    const { toast } = useToast();
    const [isPreviewMode, setIsPreviewMode] = useState(false);
    const [activeField, setActiveField] = useState(null);
    const [formChanged, setFormChanged] = useState(false);
    const subjectRef = useRef(null);
    const messageRef = useRef(null);
  
    const { data: campaignData, loading: campaignLoading, refetch: refetchCampaign } = useQuery(GET_CAMPAIGN, {
      variables: { user_id: userId ? parseInt(userId) : null },
      skip: !userId,
    });
  
    const [updateCampaign] = useMutation(UPDATE_CAMPAIGN);
    const [toggleCampaign] = useMutation(TOGGLE_CAMPAIGN);
  
    const form = useForm({
      resolver: zodResolver(formSchema),
      defaultValues: {
        subject: "",
        message: "",
      },
    });
  
    useEffect(() => {
      if (campaignData?.getCampaign) {
        form.reset({
          subject: campaignData.getCampaign.subject || '',
          message: campaignData.getCampaign.message || '',
        });
        setFormChanged(false);
      }
    }, [campaignData, form]);
  
    useEffect(() => {
      const subscription = form.watch((value, { name, type }) => {
        if (type === 'change') {
          setFormChanged(
            value.subject !== campaignData?.getCampaign?.subject ||
            value.message !== campaignData?.getCampaign?.message
          );
        }
      });
      return () => subscription.unsubscribe();
    }, [form, campaignData]);
  
    const insertPlaceholder = (placeholder) => {
      if (!activeField) return;
      const field = form.getValues(activeField);
      const element = activeField === 'subject' ? subjectRef.current : messageRef.current;
      if (element) {
        const start = element.selectionStart;
        const end = element.selectionEnd;
        const newValue = field.substring(0, start) + placeholder + field.substring(end);
        form.setValue(activeField, newValue);
        setTimeout(() => {
          element.focus();
          element.setSelectionRange(start + placeholder.length, start + placeholder.length);
        }, 0);
      }
    };
  
    const insertTemplate = (template) => {
      const [subject, ...messageParts] = template.split('\n\n');
      const message = messageParts.join('\n\n');
      form.setValue('subject', subject.replace('Subject: ', ''));
      form.setValue('message', message);
      setFormChanged(true);
    };
  
    const renderPreviewText = (text) => {
      const now = DateTime.now();
      const timeOfDay = now.hour < 12 ? 'morning' : 'afternoon';
      const dayOfWeek = now.weekdayLong;
  
      return text
        .replace(/{{first_name}}/g, 'Bruce')
        .replace(/{{last_name}}/g, 'Wayne')
        .replace(/{{company_name}}/g, 'Wayne Enterprises')
        .replace(/{{country}}/g, 'United States')
        .replace(/{{state}}/g, 'New Jersey')
        .replace(/{{city}}/g, 'Gotham City')
        .replace(/{{time_of_day}}/g, timeOfDay)
        .replace(/{{day_of_week}}/g, dayOfWeek);
    };
  
    const onSubmit = async (values) => {
      if (!userId) return;
      try {
        const wasActive = campaignData?.getCampaign?.status === 'active';
        
        if (wasActive) {
          await toggleCampaign({ variables: { user_id: parseInt(userId), active: false } });
        }
  
        const result = await updateCampaign({
          variables: {
            user_id: parseInt(userId),
            input: {
              subject: values.subject,
              message: values.message,
            }
          }
        });
  
        if (result.data?.updateCampaign) {
          if (wasActive) {
            await toggleCampaign({ variables: { user_id: parseInt(userId), active: true } });
          }
          toast({ 
            title: "Campaign updated successfully",
            description: wasActive ? "Campaign has been updated and restarted." : "Campaign has been updated.",
          });
          refetchCampaign();
          setFormChanged(false);
        }
      } catch (error) {
        console.error('Error updating campaign:', error);
        toast({
          title: "Error updating campaign",
          description: error instanceof Error ? error.message : "An unknown error occurred",
          variant: "destructive",
        });
      }
    };
  
    if (campaignLoading) {
      return (
        <Card className="w-full max-w-4xl mx-auto">
          <CardContent className="flex justify-center items-center h-64">
            <p className="text-gray-500">Loading campaign data...</p>
          </CardContent>
        </Card>
      );
    }
  
    return (
      <div className="space-y-8">
        <div className="flex justify-between items-center">
          <h2 className="text-3xl font-bold text-gray-900">Email Campaign</h2>
          <div className="flex space-x-2 bg-gray-100 p-1 rounded-lg">
            {['Edit', 'Preview'].map((mode) => (
              <Button
                key={mode}
                variant={isPreviewMode === (mode === 'Preview') ? "default" : "ghost"}
                size="sm"
                onClick={() => setIsPreviewMode(mode === 'Preview')}
                className={`${isPreviewMode === (mode === 'Preview') ? 'bg-white shadow-sm' : 'hover:bg-gray-200'} transition-all duration-200`}
              >
                {mode === 'Preview' ? <Eye className="w-4 h-4 mr-2" /> : <Edit className="w-4 h-4 mr-2" />}
                {mode}
              </Button>
            ))}
          </div>
        </div>
  
        <Card className="overflow-hidden bg-white shadow-lg rounded-xl">
          <CardHeader className="border-b border-gray-200 bg-gradient-to-r from-blue-50 to-indigo-50 p-6">
            <CardTitle className="text-xl font-semibold text-gray-800">
              {isPreviewMode ? "Campaign Preview" : "Campaign Message"}
            </CardTitle>
            <CardDescription className="text-sm text-gray-600">
              {isPreviewMode ? "See how your email will look" : "Craft your email campaign message"}
            </CardDescription>
          </CardHeader>
          
          <CardContent className="p-6">
            {!isPreviewMode ? (
              <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
                  <FormField
                    control={form.control}
                    name="subject"
                    render={({ field }) => (
                      <FormItem>
                        <div className="flex items-center justify-between mb-2">
                          <FormLabel className="text-sm font-medium text-gray-700 flex items-center">
                            <Mail className="w-4 h-4 mr-2 text-gray-500" />
                            Subject
                          </FormLabel>
                          <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                              <Button 
                                variant="outline" 
                                size="sm"
                                className="bg-blue-50 text-blue-600 border-blue-200 hover:bg-blue-100 hover:text-blue-700 transition-colors duration-200"
                              >
                                <Variable className="h-4 w-4 mr-1" />
                                Variables
                              </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent className="w-56 bg-white rounded-md shadow-lg border border-gray-200 py-1">
                              {[
                                { label: 'First Name', value: '{{first_name}}' },
                                { label: 'Last Name', value: '{{last_name}}' },
                                { label: 'Company Name', value: '{{company_name}}' },
                                { label: 'Country', value: '{{country}}' },
                                { label: 'State', value: '{{state}}' },
                                { label: 'City', value: '{{city}}' },
                                { label: 'Time of Day', value: '{{time_of_day}}' },
                                { label: 'Day of Week', value: '{{day_of_week}}' }
                              ].map((item) => (
                                <DropdownMenuItem 
                                  key={item.value}
                                  onSelect={() => insertPlaceholder(item.value)}
                                  className="px-4 py-2 hover:bg-blue-50 cursor-pointer transition-colors duration-150"
                                >
                                  {item.label}
                                </DropdownMenuItem>
                              ))}
                            </DropdownMenuContent>
                          </DropdownMenu>
                        </div>
                        <FormControl>
                          <Input 
                            placeholder="Enter email subject" 
                            className="w-full px-4 py-2 border border-gray-200 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-gray-200 transition duration-200"
                            {...field} 
                            ref={subjectRef}
                            onFocus={() => setActiveField('subject')}
                          />
                        </FormControl>
                        <FormMessage className="text-sm text-red-500 mt-1" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="message"
                    render={({ field }) => (
                      <FormItem>
                        <div className="flex items-center justify-between mb-2">
                          <FormLabel className="text-sm font-medium text-gray-700 flex items-center">
                            <MessageSquare className="w-4 h-4 mr-2 text-gray-500" />
                            Message
                          </FormLabel>
                          <div className="flex space-x-2">
                            <DropdownMenu>
                              <DropdownMenuTrigger asChild>
                                <Button 
                                  variant="outline" 
                                  size="sm"
                                  className="bg-green-50 text-green-600 border-green-200 hover:bg-green-100 hover:text-green-700 transition-colors duration-200"
                                >
                                  <Variable className="h-4 w-4 mr-2" />
                                  Variables
                                </Button>
                              </DropdownMenuTrigger>
                              <DropdownMenuContent className="w-56 bg-white rounded-md shadow-lg border border-gray-200 py-1">
                                {[
                                  { label: 'First Name', value: '{{first_name}}' },
                                  { label: 'Last Name', value: '{{last_name}}' },
                                  { label: 'Company Name', value: '{{company_name}}' },
                                  { label: 'Country', value: '{{country}}' },
                                  { label: 'State', value: '{{state}}' },
                                  { label: 'City', value: '{{city}}' },
                                  { label: 'Time of Day', value: '{{time_of_day}}' },
                                  { label: 'Day of Week', value: '{{day_of_week}}' }
                                ].map((item) => (
                                  <DropdownMenuItem 
                                    key={item.value}
                                    onSelect={() => insertPlaceholder(item.value)}
                                    className="px-4 py-2 hover:bg-green-50 cursor-pointer transition-colors duration-150"
                                  >
                                    {item.label}
                                  </DropdownMenuItem>
                                ))}
                              </DropdownMenuContent>
                            </DropdownMenu>
                            <DropdownMenu>
                              <DropdownMenuTrigger asChild>
                                <Button 
                                  variant="outline" 
                                  size="sm"
                                  className="bg-purple-50 text-purple-600 border-purple-200 hover:bg-purple-100 hover:text-purple-700 transition-colors duration-200"
                                >
                                  <FileText className="h-4 w-4 mr-2" />
                                  Templates
                                </Button>
                              </DropdownMenuTrigger>
                              <DropdownMenuContent className="w-56 bg-white rounded-md shadow-lg border border-gray-200 py-1">
                                <DropdownMenuItem onSelect={() => insertTemplate(TEMPLATE_1)} className="px-4 py-2 hover:bg-purple-50 cursor-pointer transition-colors duration-150 truncate">
                                  General Job Application
                                </DropdownMenuItem>
                                <DropdownMenuItem onSelect={() => insertTemplate(TEMPLATE_2)} className="px-4 py-2 hover:bg-purple-50 cursor-pointer transition-colors duration-150 truncate">
                                  Specific Role Application
                                </DropdownMenuItem>
                                <DropdownMenuItem onSelect={() => insertTemplate(TEMPLATE_3)} className="px-4 py-2 hover:bg-purple-50 cursor-pointer transition-colors duration-150 truncate">
                                  Sales Role Application
                                </DropdownMenuItem>
                                <DropdownMenuItem onSelect={() => insertTemplate(TEMPLATE_4)} className="px-4 py-2 hover:bg-purple-50 cursor-pointer transition-colors duration-150 truncate">
                                  Internship Application
                                </DropdownMenuItem>
                              </DropdownMenuContent>
                            </DropdownMenu>
                          </div>
                        </div>
                        <FormControl>
                          <Textarea 
                            placeholder="Your email message here" 
                            className="w-full px-4 py-2 border border-gray-200 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-gray-200 min-h-[300px] transition duration-200"
                            {...field} 
                            ref={messageRef}
                            onFocus={() => setActiveField('message')}
                          />
                        </FormControl>
                        <FormMessage className="text-sm text-red-500 mt-1" />
                      </FormItem>
                    )}
                  />
                  <Button 
                    type="submit"
                    className="w-full px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-200 flex items-center justify-center"
                    disabled={!formChanged}
                  >
                    <Save className="w-4 h-4 mr-2" />
                    Update Campaign
                  </Button>
                </form>
              </Form>
            ) : (
              <div className="space-y-6">
              <div>
                <h3 className="text-lg font-semibold text-gray-800 mb-2 flex items-center">
                  <Mail className="w-5 h-5 mr-2 text-blue-500" />
                  Subject
                </h3>
                <p className="text-gray-800 bg-gray-50 p-4 rounded-md border border-gray-200">
                  {renderPreviewText(form.getValues('subject'))}
                </p>
              </div>
              <div>
                <h3 className="text-lg font-semibold text-gray-800 mb-2 flex items-center">
                  <MessageSquare className="w-5 h-5 mr-2 text-blue-500" />
                  Message
                </h3>
                <div className="whitespace-pre-wrap text-gray-800 bg-gray-50 p-4 rounded-md border border-gray-200 min-h-[200px]">
                  {renderPreviewText(form.getValues('message'))}
                </div>
              </div>
              <div className="mt-4 text-sm text-gray-500">
                <p>Preview generated at: {DateTime.now().toLocaleString(DateTime.DATETIME_FULL)}</p>
                <p>Note: The "Time of Day" and "Day of Week" will change based on when the email is actually sent.</p>
              </div>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default EmailCampaign;