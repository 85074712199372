import React from 'react';
import Link from 'next/link';
import { Check } from 'lucide-react';
import { Button } from "../components/ui/button";
import { Card, CardHeader, CardContent, CardFooter, CardTitle } from "../components/ui/card";

const isProd = process.env.NODE_ENV === 'production';
const signupLink = isProd ? 'https://app.lancesjobfinder.com/signup' : 'http://localhost:4242/signup';

const Pricing: React.FC = () => {
  return (
    <section className="py-16 sm:py-24 bg-gray-50" id="pricing">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12 sm:mb-16">
          <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl sm:tracking-tight lg:text-5xl">
            Save hundreds of hours applying to jobs. 
          </h2>
          <p className="mt-4 text-lg sm:text-xl text-gray-500">
            No subscription required. Pay once and get access for one year.
          </p>
        </div>
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {[
            {
              title: "Student",
              price: 99,
              subheader: "Best for students and graduates",
              features: [
                "Entry-Level Connections",
                "20 Emails Per Day Maximum",
                "Real-Time Analytics",
                "Verified Email Addresses",
                "Unlimited Email Scheduling",
              ],
              popular: false,
            },
            {
              title: "Professional",
              price: 199,
              subheader: "Best for early professionals",
              features: [
                "Professional Connections",
                "30 Emails Per Day Maximum",
                "Real-Time Analytics",
                "Verified Email Addresses",
                "Unlimited Email Scheduling",
              ],
              popular: true,
            },
            {
              title: "Executive",
              price: 399,
              subheader: "Best for Directors, VPs, and C-Suite Executives",
              features: [
                "Premium Connections",
                "50 Emails Per Day Maximum",
                "Real-Time Analytics",
                "Verified Email Addresses",
                "Unlimited Email Scheduling",
              ],
              popular: false,
            }
          ].map((tier, index) => (
            <Card key={index} className={`overflow-hidden transform transition-all duration-300 hover:scale-105 ${tier.popular ? 'border-2 border-blue-500 relative bg-white' : 'bg-white'}`}>
              {tier.popular && (
                <div className="absolute top-0 right-0 bg-blue-500 text-white px-4 py-1 text-xs font-semibold">
                  Most Popular
                </div>
              )}
              <CardHeader>
                <CardTitle className="text-xl font-bold text-gray-900 mb-2">{tier.title}</CardTitle>
                <p className="text-sm text-gray-500 mb-4">{tier.subheader}</p>
                <div className="flex items-baseline mb-6">
                  <span className="text-3xl sm:text-4xl font-bold text-gray-900">${tier.price}</span>
                </div>
              </CardHeader>
              <CardContent>
                <ul className="space-y-3 mb-6">
                  {tier.features.map((feature, featureIndex) => (
                    <li key={featureIndex} className="flex items-center">
                      <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
                      <span className="text-gray-700 text-sm">{feature}</span>
                    </li>
                  ))}
                </ul>
              </CardContent>
              <CardFooter>
                <Button className="w-full bg-blue-600 hover:bg-blue-700 text-white transition-colors duration-300 py-3 rounded-full flex items-center justify-center text-sm font-semibold shadow-lg hover:shadow-xl" asChild>
                  <Link href={signupLink}>Get started</Link>
                </Button>
              </CardFooter>
            </Card>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Pricing;