import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';

// Shadcn Imports
import { Button } from "../components/ui/button";
import { Label } from "../components/ui/label";
import { Separator } from "../components/ui/separator";
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from "../components/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { useToast } from "../components/ui/use-toast";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../components/ui/table";
import { Input } from "../components/ui/input";

// Lucide Icons
import { LogOut, Shield, Mail, Globe, PlusCircle, Trash2 } from 'lucide-react';

// Components
import GmailIntegration from '../components/gmail-integration';

// GraphQL Queries and Mutations
const GET_CAMPAIGN = gql`
  query GetCampaign($user_id: Int!) {
    getCampaign(user_id: $user_id) {
      id
      status
      subject
      message
      country
      state
      city
      industry
      company_size
      email_preference
    }
  }
`;

const GET_BLOCKLIST = gql`
  query GetBlocklistDomains($user_id: Int!) {
    getBlocklistDomains(user_id: $user_id) {
      id
      domain
      created_at
    }
  }
`;

const ADD_BLOCKLIST_DOMAIN = gql`
  mutation AddBlocklistDomain($userId: Int!, $domain: String!) {
    addBlocklistDomain(userId: $userId, domain: $domain) {
      id
      domain
      created_at
    }
  }
`;

const REMOVE_BLOCKLIST_DOMAIN = gql`
  mutation RemoveBlocklistDomain($id: ID!, $userId: Int!) {
    removeBlocklistDomain(id: $id, userId: $userId) {
      id
      domain
      created_at
    }
  }
`;

const UPDATE_CAMPAIGN_EMAIL_PREFERENCE = gql`
  mutation UpdateCampaignEmailPreference($campaignId: ID!, $preference: String!) {
    updateCampaignEmailPreference(campaignId: $campaignId, preference: $preference) {
      id
      email_preference
    }
  }
`;

interface SettingsProps {
  setConnectedEmail: React.Dispatch<React.SetStateAction<string | null>>;
  onLogout: () => void;
  userId: string | null;
}

const Settings = ({ setConnectedEmail, onLogout, userId }: SettingsProps) => {
    const [emailPreference, setEmailPreference] = useState('low');
    const [newDomain, setNewDomain] = useState('');
    const { toast } = useToast();

    const { data: campaignData, loading: campaignLoading } = useQuery(GET_CAMPAIGN, {
      variables: { user_id: userId ? parseInt(userId) : null },
      skip: !userId,
    });

    const { data: blocklistData, loading: blocklistLoading, error: blocklistError, refetch: refetchBlocklist } = useQuery(GET_BLOCKLIST, {
      variables: { user_id: userId ? parseInt(userId) : 0 },
      skip: !userId || isNaN(parseInt(userId))
    });

    const [addDomain] = useMutation(ADD_BLOCKLIST_DOMAIN);
    const [removeDomain] = useMutation(REMOVE_BLOCKLIST_DOMAIN);
    const [updateEmailPreference] = useMutation(UPDATE_CAMPAIGN_EMAIL_PREFERENCE);

    useEffect(() => {
      if (campaignData?.getCampaign) {
        setEmailPreference(campaignData.getCampaign.email_preference || 'low');
      }
    }, [campaignData]);

    const handleEmailPreferenceChange = async (value: string) => {
      if (!campaignData?.getCampaign?.id) return;
      setEmailPreference(value);
      try {
        await updateEmailPreference({
          variables: {
            campaignId: campaignData.getCampaign.id,
            preference: value,
          },
        });
        toast({
          title: "Preference Updated",
          description: "Your email sending preference has been updated.",
          duration: 3000,
        });
      } catch (error) {
        console.error('Failed to update email preference:', error);
        toast({
          title: "Error",
          description: "Failed to update email preference. Please try again.",
          variant: "destructive",
        });
      }
    };

    const handleAddDomain = async (e: React.FormEvent) => {
      e.preventDefault();
      if (!userId || !newDomain.trim()) return;
      try {
        await addDomain({ 
          variables: { 
            userId: parseInt(userId),
            domain: newDomain.trim()
          } 
        });
        setNewDomain('');
        refetchBlocklist();
        toast({
          title: "Domain Added",
          description: `${newDomain} has been added to the blocklist.`,
        });
      } catch (error) {
        console.error('Failed to add domain:', error);
        toast({
          title: "Error",
          description: "Failed to add domain to blocklist. Please try again.",
          variant: "destructive",
        });
      }
    };

    const handleRemoveDomain = async (id: string) => {
      if (!userId) return;
      try {
        await removeDomain({ 
          variables: { id, userId: parseInt(userId) }
        });
        refetchBlocklist();
        toast({
          title: "Domain Removed",
          description: "The domain has been removed from the blocklist.",
        });
      } catch (error) {
        console.error('Error in handleRemoveDomain:', error);
        toast({
          title: "Error",
          description: "Failed to remove domain from blocklist. Please try again.",
          variant: "destructive",
        });
      }
    };

    return (
      <div className="space-y-8">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
          <h2 className="text-3xl font-bold text-gray-900">Settings</h2>
        </div>
  
        <div className="grid grid-cols-1 gap-6">
          <Card className="overflow-hidden bg-white shadow-sm border border-gray-200 rounded-lg">
            <CardHeader className="bg-gray-50 border-b border-gray-200 p-4">
              <CardTitle className="text-xl font-semibold text-gray-800 flex items-center">
                <Mail className="w-5 h-5 mr-2 text-blue-600" />
                Email Configuration
              </CardTitle>
            </CardHeader>
            <CardContent className="p-6 space-y-6">
              <GmailIntegration setConnectedEmail={setConnectedEmail} />
  
              <Separator className="my-4" />
  
              <div className="space-y-2">
                <Label htmlFor="email-preference" className="text-sm font-medium text-gray-700">Email Sending Frequency</Label>
                <Select onValueChange={handleEmailPreferenceChange} value={emailPreference}>
                  <SelectTrigger id="email-preference" className="w-full border-gray-300 rounded-md focus:ring focus:ring-blue-200 focus:border-blue-500">
                    <SelectValue placeholder="Select preference" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="low">Low (5-10 emails/day)</SelectItem>
                    <SelectItem value="medium">Medium (11-20 emails/day)</SelectItem>
                    <SelectItem value="high">High (21-30 emails/day)</SelectItem>
                    <SelectItem value="ultra_high">Ultra High (30-50 emails/day)</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </CardContent>
          </Card>
  
          <Card className="overflow-hidden bg-white shadow-sm border border-gray-200 rounded-lg">
            <CardHeader className="bg-gray-50 border-b border-gray-200 p-4">
              <CardTitle className="text-xl font-semibold text-gray-800 flex items-center">
                <Shield className="w-5 h-5 mr-2 text-blue-600" />
                Blocklist Management
              </CardTitle>
            </CardHeader>
            <CardContent className="p-6 space-y-6">
              <form onSubmit={handleAddDomain} className="flex space-x-2">
                <Input
                  type="text"
                  value={newDomain}
                  onChange={(e) => setNewDomain(e.target.value)}
                  placeholder="Enter domain to block"
                  className="flex-grow"
                />
                <Button type="submit" className="bg-blue-600 hover:bg-blue-700 text-white">
                  <PlusCircle className="w-4 h-4 mr-2" />
                  Add Domain
                </Button>
              </form>
  
              {blocklistLoading ? (
                <div className="flex items-center justify-center py-4">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
                </div>
              ) : blocklistError ? (
                <div className="bg-red-50 border border-red-200 rounded-lg p-4 text-red-800">
                  Error loading blocklist: {blocklistError.message}
                </div>
              ) : (
                <div className="overflow-x-auto">
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Domain</TableHead>
                        <TableHead>Added On</TableHead>
                        <TableHead>Action</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {blocklistData?.getBlocklistDomains.map((domain) => (
                        <TableRow key={domain.id} className="hover:bg-gray-50">
                          <TableCell className="font-medium">
                            <div className="flex items-center">
                              <Globe className="w-4 h-4 mr-2 text-gray-500" />
                              {domain.domain}
                            </div>
                          </TableCell>
                          <TableCell>{new Date(parseInt(domain.created_at)).toLocaleDateString()}</TableCell>
                          <TableCell>
                            <Button
                              onClick={() => handleRemoveDomain(domain.id)}
                              variant="ghost"
                              size="sm"
                              className="text-red-600 hover:text-red-700 hover:bg-red-50"
                            >
                              <Trash2 className="w-4 h-4 mr-1" />
                              Remove
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              )}
            </CardContent>
          </Card>
        </div>
      </div>
    );
  };

export default Settings;