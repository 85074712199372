import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation, gql } from '@apollo/client';

const GET_ALL_USERS = gql`
  query GetAllUsers {
    getAllUsers {
      id
      email
      active
      expiration_date
    }
  }
`;

const IMPERSONATE_USER = gql`
  mutation ImpersonateUser($userId: ID!) {
    impersonateUser(userId: $userId) {
      token
      userId
    }
  }
`;

const SEND_TEST_EMAIL = gql`
  mutation SendTestEmail($userId: ID!) {
    sendTestEmail(userId: $userId)
  }
`;

interface User {
  id: string;
  email: string;
  active: boolean;
  expiration_date: string | null;
}

const SuperAdmin: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [testEmailStatus, setTestEmailStatus] = useState<string | null>(null);

  const { loading: usersLoading, error: usersError, data: usersData } = useQuery(GET_ALL_USERS);
  const [impersonateUser] = useMutation(IMPERSONATE_USER);
  const [sendTestEmail] = useMutation(SEND_TEST_EMAIL);

  if (usersLoading) return <p>Loading...</p>;
  if (usersError) return <p>Error loading users: {usersError.message}</p>;

  const filteredUsers = usersData?.getAllUsers.filter((user: User) =>
    user.email.toLowerCase().includes(searchTerm.toLowerCase())
  ) || [];

  const handleUserSelect = (user: User) => {
    setSelectedUser(user);
  };

  const handleImpersonateUser = async () => {
    if (!selectedUser) return;

    try {
      const { data } = await impersonateUser({
        variables: { userId: selectedUser.id }
      });

      if (data.impersonateUser) {
        localStorage.setItem('token', data.impersonateUser.token);
        localStorage.setItem('impersonating', 'true');
        window.location.href = '/dashboard';
      }
    } catch (error) {
      console.error('Error impersonating user:', error);
    }
  };

  const handleSendTestEmail = async () => {
    if (!selectedUser) {
      setTestEmailStatus('Please select a user first.');
      return;
    }
  
    try {
      setTestEmailStatus('Sending...');
      const { data } = await sendTestEmail({
        variables: { userId: selectedUser.id }
      });
      if (data.sendTestEmail) {
        setTestEmailStatus('Test email sent successfully!');
      } else {
        setTestEmailStatus('Failed to send test email.');
      }
    } catch (error) {
      console.error('Error sending test email:', error);
      setTestEmailStatus('Error sending test email.');
    }
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl font-bold mb-4">Super Admin Panel</h1>
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search users..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-2 border rounded"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <h2 className="text-xl font-semibold mb-2">User List</h2>
          <ul className="border rounded divide-y">
            {filteredUsers.map((user: User) => (
              <li
                key={user.id}
                className={`p-2 cursor-pointer hover:bg-gray-100 ${selectedUser?.id === user.id ? 'bg-blue-100' : ''}`}
                onClick={() => handleUserSelect(user)}
              >
                {user.email} - {user.active ? 'Active' : 'Inactive'}
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h2 className="text-xl font-semibold mb-2">User Details</h2>
          {selectedUser ? (
            <div className="border rounded p-4">
              <p><strong>Email:</strong> {selectedUser.email}</p>
              <p><strong>Status:</strong> {selectedUser.active ? 'Active' : 'Inactive'}</p>
              <p><strong>Expiration Date:</strong> {selectedUser.expiration_date || 'N/A'}</p>
              <button
                onClick={handleImpersonateUser}
                className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Impersonate User
              </button>
            </div>
          ) : (
            <p>Select a user to view details</p>
          )}
        </div>
      </div>
      <div className="mt-8">
        <h2 className="text-xl font-semibold mb-2">Test Open Rate Tracking</h2>
        <button
          onClick={handleSendTestEmail}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Send Test Email
        </button>
        {testEmailStatus && (
          <p className="mt-2">{testEmailStatus}</p>
        )}
      </div>
    </div>
  );
};

export default SuperAdmin;