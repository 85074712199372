import React, { useEffect, useState } from 'react';
import { Origami, Menu, X } from 'lucide-react';
import { Button } from "../components/ui/button";

const isProd = process.env.NODE_ENV === 'production';
const signinLink = isProd ? 'https://app.lancesjobfinder.com/signin' : 'http://localhost:4242/signin';
const signupLink = isProd ? 'https://app.lancesjobfinder.com/signup' : 'http://localhost:4242/signup';

const Navbar = ({ scrollTo }) => {
  const [isHomePage, setIsHomePage] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const currentPath = window.location.pathname;
    setIsHomePage(currentPath === '/');
  }, []);

  const handleNavigation = (sectionId) => {
    if (isHomePage && typeof scrollTo === 'function') {
      scrollTo(sectionId);
    } else {
      window.location.href = `/#${sectionId}`;
    }
    setIsMenuOpen(false);
  };

  return (
    <nav className="fixed w-full bg-white backdrop-blur-md bg-opacity-80 shadow-sm z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <div className="flex items-center">
            <a href="/" className="flex items-center">
              <Origami className="h-8 w-8 text-blue-600" />
              <span className="ml-2 text-xl font-semibold text-gray-900">Lance's Job Finder</span>
            </a>
          </div>
          <div className="hidden md:flex items-center space-x-6">
            <button onClick={() => handleNavigation('hero')} className="text-gray-600 hover:bg-gray-100 px-3 py-2 rounded transition-colors duration-300">Demo</button>
            <button onClick={() => handleNavigation('pricing')} className="text-gray-600 hover:bg-gray-100 px-3 py-2 rounded transition-colors duration-300">Pricing</button>
            <button onClick={() => handleNavigation('testimonials')} className="text-gray-600 hover:bg-gray-100 px-3 py-2 rounded transition-colors duration-300">Testimonials</button>
            <button onClick={() => handleNavigation('faq')} className="text-gray-600 hover:bg-gray-100 px-3 py-2 rounded transition-colors duration-300">FAQs</button>
            <Button variant="outline" className="text-blue-600 hover:text-blue-700 hover:bg-blue-50 transition-colors duration-300" asChild>
              <a href={signinLink}>Sign in</a>
            </Button>
            <Button className="bg-blue-600 text-white hover:bg-blue-700 transition-colors duration-300" asChild>
              <a href={signupLink}>Get started</a>
            </Button>
          </div>
          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-gray-600 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
            >
              {isMenuOpen ? (
                <X className="h-6 w-6" aria-hidden="true" />
              ) : (
                <Menu className="h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </div>
      {/* Mobile menu */}
      {isMenuOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <button onClick={() => handleNavigation('hero')} className="block w-full text-left text-gray-600 hover:bg-gray-100 px-3 py-2 rounded transition-colors duration-300">Demo</button>
            <button onClick={() => handleNavigation('pricing')} className="block w-full text-left text-gray-600 hover:bg-gray-100 px-3 py-2 rounded transition-colors duration-300">Pricing</button>
            <button onClick={() => handleNavigation('testimonials')} className="block w-full text-left text-gray-600 hover:bg-gray-100 px-3 py-2 rounded transition-colors duration-300">Testimonials</button>
            <button onClick={() => handleNavigation('faq')} className="block w-full text-left text-gray-600 hover:bg-gray-100 px-3 py-2 rounded transition-colors duration-300">FAQs</button>
          </div>
          <div className="pt-4 pb-3 border-t border-gray-200">
            <div className="flex items-center px-5">
              <Button variant="outline" className="w-full text-blue-600 hover:text-blue-700 hover:bg-blue-50 transition-colors duration-300 mr-2" asChild>
                <a href={signinLink}>Sign in</a>
              </Button>
              <Button className="w-full bg-blue-600 text-white hover:bg-blue-700 transition-colors duration-300" asChild>
                <a href={signupLink}>Get started</a>
              </Button>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;