import React, { useState, useEffect, useCallback } from 'react';
import { Button } from "./ui/button";
import { useToast } from "./ui/use-toast";
import { useNavigate } from 'react-router-dom';
import { Mail, CheckCircle2, AlertCircle } from 'lucide-react';

const GmailIntegration = ({ setConnectedEmail }: { setConnectedEmail: (email: string | null) => void }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { toast } = useToast();
  const navigate = useNavigate();
  const API_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:4242' : process.env.REACT_APP_API_URL;

  const checkGmailConnection = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`${API_URL}/api/check-gmail-connection`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setIsConnected(data.isConnected);
        setConnectedEmail(data.isConnected ? 'Gmail' : null);
      } else {
        throw new Error('Failed to check Gmail connection');
      }
    } catch (error) {
      console.error('Failed to check Gmail connection:', error);
      setIsConnected(false);
      setConnectedEmail(null);
      setError('Failed to check Gmail connection. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [setConnectedEmail, API_URL]);

  useEffect(() => {
    checkGmailConnection();
  }, [checkGmailConnection]);

  const handleConnect = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`${API_URL}/api/initiate-gmail-auth`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const { authUrl } = await response.json();
      
      const authWindow = window.open(authUrl, 'Gmail Authorization', 'width=600,height=600');
      
      if (!authWindow) {
        throw new Error('Popup blocked. Please allow popups for this site.');
      }
  
      const handleMessage = (event: MessageEvent) => {
        if (event.origin !== window.location.origin) return;
        if (event.data === 'gmail_auth_success') {
          window.removeEventListener('message', handleMessage);
          setIsLoading(false);
          checkGmailConnection();
          navigate('/settings'); 
          toast({
            title: "Gmail Connected",
            description: "Your Gmail account has been successfully connected for sending emails!",
            duration: 5000,
          });
        }
      };
  
      window.addEventListener('message', handleMessage);
  
      setTimeout(() => {
        window.removeEventListener('message', handleMessage);
        setIsLoading(false);
      }, 300000);
  
    } catch (error) {
      console.error('Failed to initiate Gmail auth:', error);
      setError(error instanceof Error ? error.message : "Failed to connect Gmail. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDisconnect = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`${API_URL}/api/disconnect-gmail`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to disconnect Gmail');
      }
      
      const data = await response.json();
      setIsConnected(false);
      setConnectedEmail(null);
      toast({
        title: "Gmail Disconnected",
        description: data.message || "Your Gmail account has been successfully disconnected.",
        duration: 5000,
      });
    } catch (error) {
      console.error('Failed to disconnect Gmail:', error);
      setError(error instanceof Error ? error.message : "Failed to disconnect Gmail. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };
  
  if (isLoading) {
    return (
      <div className="flex items-center justify-center py-4">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 rounded-lg p-4">
        <AlertCircle className="w-5 h-5 text-red-600 mr-2 inline" />
        <span className="text-red-800">{error}</span>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {isConnected ? (
        <div className="bg-green-50 border border-green-200 rounded-lg p-4 flex items-center justify-between">
          <div className="flex items-center">
            <CheckCircle2 className="w-5 h-5 text-green-600 mr-2" />
            <span className="text-green-800 font-medium">Gmail Connected</span>
          </div>
          <Button 
            onClick={handleDisconnect}
            variant="outline"
            size="sm"
            className="text-red-600 border-red-300 hover:bg-red-50"
          >
            Disconnect
          </Button>
        </div>
      ) : (
        <div className="bg-blue-50 border border-blue-200 rounded-lg p-4 flex items-center justify-between">
          <div className="flex items-center">
            <AlertCircle className="w-5 h-5 text-blue-600 mr-2" />
            <span className="text-blue-800 font-medium">No Gmail account connected for sending</span>
          </div>
          <Button 
            onClick={handleConnect}
            variant="outline"
            size="sm"
            className="text-blue-600 border-blue-300 hover:bg-blue-100"
          >
            Connect Gmail (Send Only)
          </Button>
        </div>
      )}
    </div>
  );
};

export default GmailIntegration;