import React, { useState, useEffect } from 'react';
import { useQuery, useLazyQuery, useMutation, gql } from '@apollo/client';

// Shadcn Imports
import { Button } from "../components/ui/button";
import { Label } from "../components/ui/label";
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from "../components/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { useToast } from "../components/ui/use-toast";
import { Badge } from "../components/ui/badge";

// Lucide Icons
import { Globe, MapPin, Building, Briefcase, Users, X, AlertTriangle } from 'lucide-react';

// GraphQL Queries and Mutations
const SEARCH_CONTACTS = gql`
  query SearchContacts($country: [String], $state: [String], $city: [String], $industry: [String], $company_size: [String]) {
    searchContacts(country: $country, state: $state, city: $city, industry: $industry, company_size: $company_size) {
      count
      contacts {
        id
        firstName
        lastName
        email
        companyName
        country
        state
        city
        industry
        company_size
      }
    }
  }
`;

const UPDATE_CAMPAIGN = gql`
  mutation UpdateCampaign($user_id: Int!, $input: CampaignInput!) {
    updateCampaign(user_id: $user_id, input: $input) {
      id
      subject
      message
      country
      state
      city
      industry
      company_size
      email_preference
    }
  }
`;

const GET_CAMPAIGN = gql`
  query GetCampaign($user_id: Int!) {
    getCampaign(user_id: $user_id) {
      id
      status
      subject
      message
      country
      state
      city
      industry
      company_size
      email_preference
    }
  }
`;

// Data for dropdowns
const industryCategories = {
  "Consumer Goods & Retail": ["Apparel & Fashion", "Consumer Goods", "Consumer Services", "Cosmetics", "Furniture", "Luxury Goods & Jewelry", "Retail", "Wholesale"],
  "Education & Training": ["E-Learning", "Education Management", "Higher Education", "Libraries", "Professional Training & Coaching"],
  "Energy & Environment": ["Oil & Energy", "Renewables & Environment", "Utilities"],
  "Finance & Business Services": ["Accounting", "Banking", "Capital Markets", "Financial Services", "Insurance", "Investment Management"],
  "Food & Agriculture": ["Farming", "Food & Beverages", "Food Production", "Restaurants"],
  "Government & Public Sector": ["Defense & Space", "Government Administration", "Political Organization", "Public Safety"],
  "Healthcare & Wellness": ["Alternative Medicine", "Biotechnology", "Health, Wellness & Fitness", "Hospital & Health Care", "Medical Devices", "Mental Health Care", "Pharmaceuticals"],
  "Hospitality & Tourism": ["Hospitality"],
  "International Business": ["Import & Export", "International Trade & Development", "Outsourcing/Offshoring"],
  "Manufacturing & Engineering": ["Automotive", "Aviation & Aerospace", "Building Materials", "Chemicals", "Civil Engineering", "Electrical/Electronic Manufacturing", "Machinery", "Mechanical or Industrial Engineering"],
  "Marketing & Communications": ["Advertising & Marketing", "Events Services", "Graphic Design", "Public Relations & Communications"],
  "Media & Entertainment": ["Entertainment", "Gambling & Casinos", "Media Production", "Music", "Newspapers", "Online Media", "Performing Arts", "Publishing", "Sports"],
  "Miscellaneous Industries": ["Design", "Environmental Services", "Information Services", "Paper & Forest Products", "Printing", "Textiles"],
  "Nonprofit & Social Services": ["Fund-Raising", "Nonprofit Organization Management", "Think Tanks"],
  "Professional Services": ["Business Supplies & Equipment", "Human Resources", "Law Practice", "Legal Services", "Management Consulting", "Market Research", "Staffing & Recruiting"],
  "Real Estate & Construction": ["Construction", "Facilities Services", "Real Estate"],
  "Research & Development": ["Research"],
  "Security & Investigation": ["Security & Investigations"],
  "Technology": ["Computer & Network Security", "Computer Hardware", "Computer Networking", "Computer Software", "Consumer Electronics", "Information Technology & Services", "Internet", "Semiconductors", "Telecommunications"],
  "Transportation & Logistics": ["Logistics & Supply Chain", "Maritime", "Package/Freight Delivery", "Transportation/Trucking/Railroad"]
};

const states = [
  "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", "Georgia",
  "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland",
  "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey",
  "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina",
  "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"
];

const cities = [
  "Atlanta", "Austin", "Baltimore", "Boston", "Charlotte", "Chicago", "Columbus", "Dallas", "Denver", "Detroit", "El Paso", 
  "Fort Worth", "Houston", "Indianapolis", "Jacksonville", "Kansas City", "Las Vegas", "Los Angeles", "Louisville", "Memphis", 
  "Miami", "Milwaukee", "Minneapolis", "Nashville", "New Orleans", "New York", "Oklahoma City", "Philadelphia", "Phoenix", 
  "Portland", "Raleigh", "Sacramento", "San Antonio", "San Diego", "San Francisco", "Seattle", "Tampa"
];

interface TargetAudienceProps {
  userId: string | null;
}

const TargetAudience = ({ userId }) => {
  const { toast } = useToast();
  const [formState, setFormState] = useState({
    country: [],
    state: [],
    city: [],
    industry: [],
    company_size: []
  });
  const [totalContacts, setTotalContacts] = useState(0);

  const companySizeMapping = {
    'Small (11-50 employees)': '11-50',
    'Medium (51-200 employees)': '51-200',
    'Large (201-500 employees)': '201-500'
  };

  const reversedCompanySizeMapping = Object.fromEntries(
    Object.entries(companySizeMapping).map(([key, value]) => [value, key])
  );

  const { data: campaignData, loading: campaignLoading, refetch: refetchCampaign } = useQuery(GET_CAMPAIGN, {
    variables: { user_id: userId ? parseInt(userId) : null },
    skip: !userId,
  });

  const [updateCampaign, { loading: updateLoading }] = useMutation(UPDATE_CAMPAIGN);

  const [searchContacts, { loading: searchLoading }] = useLazyQuery(SEARCH_CONTACTS, {
    onCompleted: (data) => setTotalContacts(data.searchContacts.count),
    onError: (error) => {
      console.error('Error fetching contact count:', error);
      toast({
        title: "Error",
        description: "Failed to fetch contact count. Please try again.",
        variant: "destructive",
      });
    },
  });

  useEffect(() => {
    if (campaignData?.getCampaign) {
      setFormState({
        country: campaignData.getCampaign.country || [],
        state: campaignData.getCampaign.state || [],
        city: campaignData.getCampaign.city || [],
        industry: campaignData.getCampaign.industry || [],
        company_size: campaignData.getCampaign.company_size || []
      });
    }
  }, [campaignData]);

  useEffect(() => {
    searchContacts({
      variables: Object.fromEntries(
        Object.entries(formState).map(([key, value]) => [key, value.length > 0 ? value : null])
      )
    });
  }, [formState, searchContacts]);

  const handleSelectChange = (field, value) => {
    if (field === 'company_size') {
      const storedValue = companySizeMapping[value];
      setFormState(prev => ({
        ...prev,
        [field]: prev[field].includes(storedValue)
          ? prev[field].filter(v => v !== storedValue)
          : [...prev[field], storedValue]
      }));
    } else {
      setFormState(prev => ({
        ...prev,
        [field]: prev[field].includes(value)
          ? prev[field].filter(v => v !== value)
          : [...prev[field], value]
      }));
    }
  };
  const handleUpdateCampaign = async () => {
    if (!userId) return;
    try {
      await updateCampaign({
        variables: {
          user_id: parseInt(userId),
          input: formState
        },
      });
      toast({
        title: "Campaign Updated",
        description: "Your campaign audience has been updated successfully.",
      });
      refetchCampaign();
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update campaign. Please try again.",
        variant: "destructive",
      });
    }
  };

  const renderSelect = (field, label, options, icon) => (
    <div className="space-y-2">
      <Label htmlFor={field} className="text-sm font-medium text-gray-700 flex items-center">
        {icon}
        <span className="ml-2">{label}</span>
      </Label>
      <Select onValueChange={(value) => handleSelectChange(field, value)}>
        <SelectTrigger id={`${field}-select`} className="w-full border border-gray-200 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-gray-200">
          <SelectValue placeholder={`Select ${label.toLowerCase()}`} />
        </SelectTrigger>
        <SelectContent className="max-h-60 overflow-y-auto">
          {options.map(option => (
            <SelectItem key={option} value={option}>{option}</SelectItem>
          ))}
        </SelectContent>
      </Select>
      <div className="flex flex-wrap gap-2 mt-2">
        {formState[field].map((value) => (
          <Badge key={value} variant="secondary" className="px-2 py-1 text-sm bg-blue-50 text-blue-700 rounded-full flex items-center">
            {field === 'company_size' ? reversedCompanySizeMapping[value] : value}
            <Button 
              variant="ghost"
              size="sm"
              className="ml-1 p-0 h-auto hover:bg-transparent"
              onClick={() => handleSelectChange(field, field === 'company_size' ? reversedCompanySizeMapping[value] : value)}
            >
              <X className="h-3 w-3 text-blue-700" />
            </Button>
          </Badge>
        ))}
      </div>
    </div>
  );

  const renderActiveAudienceSection = (title, data, icon) => (
    <div className="bg-gray-50 p-4 rounded-lg mb-4">
      <h3 className="font-semibold text-gray-700 flex items-center mb-2">
        {icon}
        <span className="ml-2">{title}</span>
      </h3>
      <p className="text-gray-600">
        {data && data.length > 0 ? data.join(', ') : `All ${title.toLowerCase()} selected`}
      </p>
    </div>
  );

  if (campaignLoading) {
    return <div className="flex justify-center items-center h-64"><p>Loading...</p></div>;
  }

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center">
        <h2 className="text-3xl font-bold text-gray-900">Target Audience</h2>
        <div className="bg-blue-50 text-blue-700 px-4 py-2 rounded-full font-semibold">
          {searchLoading ? 'Calculating...' : `${totalContacts.toLocaleString()} Contacts`}
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-5 gap-8">
        {/* Active Audience Column */}
        <div className="lg:col-span-2">
          <Card className="overflow-hidden bg-white shadow-lg rounded-xl">
            <CardHeader className="border-b border-gray-200 bg-gradient-to-r from-green-50 to-teal-50 p-6">
              <CardTitle className="text-xl font-semibold text-gray-800">Active Audience</CardTitle>
              <CardDescription className="text-sm text-gray-600">
                Your current campaign targeting
              </CardDescription>
            </CardHeader>
            <CardContent className="p-6 space-y-4">
              {renderActiveAudienceSection('Countries', campaignData?.getCampaign?.country, <Globe className="w-5 h-5 text-blue-500" />)}
              {renderActiveAudienceSection('States', campaignData?.getCampaign?.state, <MapPin className="w-5 h-5 text-blue-500" />)}
              {renderActiveAudienceSection('Cities', campaignData?.getCampaign?.city, <Building className="w-5 h-5 text-blue-500" />)}
              {renderActiveAudienceSection('Industries', campaignData?.getCampaign?.industry, <Briefcase className="w-5 h-5 text-blue-500" />)}
              {renderActiveAudienceSection('Company Sizes', campaignData?.getCampaign?.company_size, <Users className="w-5 h-5 text-blue-500" />)}
            </CardContent>
          </Card>
        </div>

        {/* Audience Selection Column */}
        <div className="lg:col-span-3">
          <Card className="overflow-hidden bg-white shadow-lg rounded-xl">
            <CardHeader className="border-b border-gray-200 bg-gradient-to-r from-blue-50 to-indigo-50 p-6">
              <CardTitle className="text-xl font-semibold text-gray-800">Refine Audience</CardTitle>
              <CardDescription className="text-sm text-gray-600">
                Adjust your target audience for the campaign
              </CardDescription>
            </CardHeader>
            
            <CardContent className="p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {renderSelect('country', 'Countries', ['Australia', 'Canada', 'United Kingdom', 'United States'], <Globe className="w-5 h-5 text-blue-500" />)}
              {renderSelect('state', 'States', states, <MapPin className="w-5 h-5 text-blue-500" />)}
              {renderSelect('city', 'Cities', cities, <Building className="w-5 h-5 text-blue-500" />)}
              {renderSelect('industry', 'Industries', Object.keys(industryCategories), <Briefcase className="w-5 h-5 text-blue-500" />)}
              {renderSelect('company_size', 'Company Sizes', Object.keys(companySizeMapping), <Users className="w-5 h-5 text-blue-500" />)}
            </div>
          </CardContent>

            <CardFooter className="bg-gray-50 border-t border-gray-200 p-6">
              <div className="w-full space-y-4">
                <Button 
                  onClick={handleUpdateCampaign}
                  className="w-full bg-blue-600 hover:bg-blue-700 text-white"
                  disabled={totalContacts < 1000 || updateLoading}
                >
                  {updateLoading ? 'Updating...' : 'Update Campaign'}
                </Button>
                {totalContacts < 1000 && (
                  <div className="flex items-center space-x-2 text-amber-600 bg-amber-50 p-3 rounded-md">
                    <AlertTriangle className="h-5 w-5" />
                    <p className="text-sm">
                      Your audience must include at least 1,000 contacts to update the campaign.
                    </p>
                  </div>
                )}
              </div>
            </CardFooter>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default TargetAudience;
