import React, { useEffect, useRef, useState } from 'react';
import { Card, CardContent } from "../components/ui/card";
import { Avatar, AvatarFallback, AvatarImage } from "../components/ui/avatar";
import { Star } from 'lucide-react';

// Import the testimonial images
const TestimonialED = require('../assets/images/Testimonial_ED.png');
const TestimonialLB = require('../assets/images/Testimonial_LB.png');
const TestimonialLR = require('../assets/images/Testimonial_LR.png');
const TestimonialRG = require('../assets/images/Testimonial_RG.png');

const TestimonialCard = ({ testimonial }) => (
  <Card className="h-full shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
    <CardContent className="p-4 sm:p-6 lg:p-8 flex flex-col h-full">
      <div className="flex items-center mb-4">
        <Avatar className="w-10 h-10 sm:w-12 sm:h-12 mr-3 sm:mr-4">
          <AvatarImage src={testimonial.avatar} alt={testimonial.name} />
          <AvatarFallback>{testimonial.name.split(' ').map(n => n[0]).join('')}</AvatarFallback>
        </Avatar>
        <div>
          <h3 className="font-semibold text-base sm:text-lg text-gray-900">{testimonial.name}</h3>
          <p className="text-xs sm:text-sm text-gray-600">{testimonial.role}</p>
        </div>
      </div>
      <div className="flex mb-3 sm:mb-4">
        {[...Array(5)].map((_, i) => (
          <Star key={i} className="w-4 h-4 sm:w-5 sm:h-5 text-yellow-400 fill-current" />
        ))}
      </div>
      <p className="text-gray-700 italic flex-grow text-xs sm:text-sm lg:text-base">"{testimonial.content}"</p>
    </CardContent>
  </Card>
);  

const TestimonialsSection = () => {
  const scrollRef = useRef(null);
  const [isHovering, setIsHovering] = useState(false);
  
  const testimonials = [
    {
      name: "Evan D",
      role: "Client Business Lead",
      avatar: TestimonialED,
      content: "Absolute game changer when it comes to finding your next job. Its technology does most of the heavy lifting. My time is now spent sifting through all the companies who are contacting me vs applying to endless open positions hoping someone responds."
    },
    {
      name: "Luke R",
      role: "Hiring Director",
      avatar: TestimonialLR,
      content: "Lance's Job Finder worked absolutely amazing for me! I had 10 interview offers in 2 weeks! I had multiple offers coming in and I am so glad I was able to work with Lance! Would highly recommend!"
    },
    {
      name: "Robert G",
      role: "Senior Technical Project Manager",
      avatar: TestimonialRG,
      content: "I've received a steady stream of leads for positions that match my background and skills. Within a few weeks I had so many interviews I had to pause the campaign. I would highly recommend it!"
    },
    {
      name: "Luke B",
      role: "Product Manager",
      avatar: TestimonialLB,
      content: "Lance's Job Finder was HUGE in me getting my job. Within 4 weeks, I took 60 interview calls and received 4 job offers. I tried applying to jobs through LinkedIn before but every application I sent in had 1k+ other applicants - don't waste your time with LinkedIn or other platforms. Use Lance's Job Finder, it will save you so much time."
    },
  ];

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    let scrollInterval;

    const startScroll = () => {
      scrollInterval = setInterval(() => {
        if (scrollContainer.scrollLeft >= scrollContainer.scrollWidth / 2) {
          scrollContainer.scrollLeft = 0;
        } else {
          scrollContainer.scrollLeft += 1;
        }
      }, 30);
    };

    const stopScroll = () => {
      clearInterval(scrollInterval);
    };

    if (!isHovering) {
      startScroll();
    }

    return () => {
      stopScroll();
    };
  }, [isHovering]);

  return (
    <section className="py-16 sm:py-24 bg-gradient-to-br from-purple-50 to-blue-50" id="testimonials">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl sm:text-4xl font-bold text-center text-gray-900 mb-12 sm:mb-16">What Our Users Say</h2>
        <div className="relative">
          <div className="absolute left-0 top-0 bottom-0 w-8 sm:w-16 lg:w-32 bg-gradient-to-r from-purple-50 to-transparent z-10"></div>
          <div className="absolute right-0 top-0 bottom-0 w-8 sm:w-16 lg:w-32 bg-gradient-to-l from-blue-50 to-transparent z-10"></div>
          <div 
            ref={scrollRef}
            className="flex overflow-x-hidden"
            style={{ WebkitOverflowScrolling: 'touch' }}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            onTouchStart={() => setIsHovering(true)}
            onTouchEnd={() => setIsHovering(false)}
          >
            <div className="flex animate-scroll">
              {[...testimonials, ...testimonials].map((testimonial, index) => (
                <div key={index} className="w-[85vw] sm:w-1/2 lg:w-1/3 flex-shrink-0 px-2 sm:px-4">
                  <TestimonialCard testimonial={testimonial} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;