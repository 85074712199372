import React, { useState, useEffect, useCallback } from 'react';
import { useToast } from "../components/ui/use-toast";
import { Button } from "../components/ui/button";
import { Card, CardContent } from "../components/ui/card";
import { Progress } from "../components/ui/progress";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { motion, AnimatePresence } from 'framer-motion';
import { UploadCloud, Download, RefreshCw, CheckCircle, XCircle, AlertTriangle } from 'lucide-react';

const BounceTester = () => {
  const { toast } = useToast();
  const [file, setFile] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [processedResults, setProcessedResults] = useState(null);
  const [progress, setProgress] = useState(0);
  const [columns, setColumns] = useState([]);
  const [emailColumn, setEmailColumn] = useState('');

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile && selectedFile.type === 'text/csv') {
      setFile(selectedFile);
      const headers = await getCSVHeaders(selectedFile);
      setColumns(headers);
      setEmailColumn(headers[0] || '');
    } else {
      toast({
        title: "Invalid file type",
        description: "Please upload a CSV file.",
        variant: "destructive",
      });
    }
  };

  const getCSVHeaders = (file: File): Promise<string[]> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result as string;
        const firstLine = (content as string).split('\n')[0];
        const headers = firstLine.split(',').map(header => header.trim());
        resolve(headers);
      };
      reader.readAsText(file);
    });
  };

  const handleUpload = async () => {
    if (!file) {
      toast({
        title: "No file selected",
        description: "Please select a CSV file to upload.",
        variant: "destructive",
      });
      return;
    }

    if (!emailColumn) {
      toast({
        title: "Email column not selected",
        description: "Please select the column containing email addresses.",
        variant: "destructive",
      });
      return;
    }
  
    setIsProcessing(true);
    setProgress(0);
    setProcessedResults(null);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('emailColumn', emailColumn);
  
    try {
      const response = await fetch('/api/clean-email-list', {
        method: 'POST',
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error('Server responded with an error');
      }
  
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = '';
  
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
  
        buffer += decoder.decode(value, { stream: true });
  
        let newlineIndex;
        while ((newlineIndex = buffer.indexOf('\n')) !== -1) {
          const chunk = buffer.slice(0, newlineIndex);
          buffer = buffer.slice(newlineIndex + 1);
          
          try {
            const results = JSON.parse(chunk);
            if (results.totalProcessed !== undefined) {
              setProgress(Math.round((results.totalProcessed / results.totalEmailsInFile) * 100));
            } else {
              setProcessedResults(results);
              setProgress(100);
            }
          } catch (e) {
            console.error('Error parsing JSON:', e);
          }
        }
      }
  
      if (buffer) {
        try {
          const finalResults = JSON.parse(buffer);
          setProcessedResults(finalResults);
          setProgress(100);
        } catch (e) {
          console.error('Error parsing final JSON:', e);
        }
      }
  
      toast({
        title: "Processing complete",
        description: `Total processed: ${processedResults?.totalProcessed}. Valid: ${processedResults?.validCount}, Invalid: ${processedResults?.invalidCount}`,
      });
    } catch (error) {
      console.error('Error cleaning email list:', error);
      toast({
        title: "Error",
        description: "Failed to process the email list. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsProcessing(false);
    }
  };

  const handleDownload = (type) => {
    if (!processedResults) return;

    const emails = type === 'valid' ? processedResults.validEmails : processedResults.invalidEmails;
    const csvContent = [
      columns,
      ...emails.map(item => columns.map(col => item[col] || ''))
    ].map(e => e.join(",")).join("\n");

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${type}_emails.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="max-w-4xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <motion.h1 
        className="text-4xl font-bold mb-8 text-center text-gray-900"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Email List Cleaner
      </motion.h1>
      
      <Card className="overflow-hidden bg-white shadow-lg rounded-2xl">
        <CardContent className="p-8">
          <AnimatePresence>
            {!file && !isProcessing && (
              <motion.div
                className="flex items-center justify-center"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                transition={{ duration: 0.3 }}
              >
                <label
                  htmlFor="file-upload"
                  className="flex flex-col items-center justify-center w-full h-64 border-2 border-blue-300 border-dashed rounded-xl cursor-pointer bg-gray-50 hover:bg-blue-50 transition-colors duration-300"
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <UploadCloud className="w-12 h-12 mb-4 text-blue-500" />
                    <p className="mb-2 text-sm text-gray-700">
                      <span className="font-semibold">Click to upload</span> or drag and drop
                    </p>
                    <p className="text-xs text-gray-500">CSV file (MAX. 5000 emails)</p>
                  </div>
                  <input
                    id="file-upload"
                    type="file"
                    accept=".csv"
                    className="hidden"
                    onChange={handleFileChange}
                  />
                </label>
              </motion.div>
            )}
          </AnimatePresence>
          
          {file && !isProcessing && (
            <motion.div
              className="text-center"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <p className="text-lg font-semibold text-gray-700 mb-4">
                Selected file: {file.name}
              </p>
              <div className="mb-4">
                <Select value={emailColumn} onValueChange={setEmailColumn}>
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select email column" />
                  </SelectTrigger>
                  <SelectContent>
                    {columns.map((column) => (
                      <SelectItem key={column} value={column}>
                        {column}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <Button 
                onClick={handleUpload}
                className="w-full max-w-xs bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded-full shadow-md transition-all duration-300"
              >
                Clean Email List
              </Button>
            </motion.div>
          )}
          
          {isProcessing && (
            <motion.div 
              className="text-center"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <RefreshCw className="w-12 h-12 text-blue-500 animate-spin mx-auto mb-4" />
              <h3 className="text-xl font-semibold text-gray-800 mb-2">Processing your list</h3>
              <p className="text-sm text-gray-600 mb-4">This may take a few moments...</p>
              <Progress value={progress} className="w-full h-2 bg-blue-100" />
              <p className="text-sm text-gray-600 mt-2">Progress: {progress.toFixed(0)}%</p>
            </motion.div>
          )}
          
          {processedResults && !isProcessing && (
            <motion.div 
              className="mt-8"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
            >
              <h2 className="text-2xl font-bold mb-6 text-center text-gray-900">Results Summary</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
                <ResultCard
                  title="Total Processed"
                  value={processedResults.totalProcessed}
                  icon={<CheckCircle className="w-8 h-8 text-blue-500" />}
                />
                <ResultCard
                  title="Valid Emails"
                  value={processedResults.validCount}
                  icon={<CheckCircle className="w-8 h-8 text-green-500" />}
                />
                <ResultCard
                  title="Invalid Emails"
                  value={processedResults.invalidCount}
                  icon={<AlertTriangle className="w-8 h-8 text-yellow-500" />}
                />
              </div>
              <div className="flex justify-center space-x-4">
                <DownloadButton onClick={() => handleDownload('valid')} label="Download Valid Emails" />
                <DownloadButton onClick={() => handleDownload('invalid')} label="Download Invalid Emails" />
              </div>
            </motion.div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

const ResultCard = ({ title, value, icon }) => (
  <div className="bg-white p-6 rounded-xl shadow-md text-center">
    <div className="flex justify-center mb-4">{icon}</div>
    <h3 className="text-xl font-semibold text-gray-800 mb-2">{title}</h3>
    <p className="text-3xl font-bold text-blue-600">{value.toLocaleString()}</p>
  </div>
);

const DownloadButton = ({ onClick, label }) => (
  <Button
    onClick={onClick}
    variant="outline"
    className="flex items-center space-x-2 bg-white text-blue-600 border-blue-300 hover:bg-blue-50 transition-colors duration-300"
  >
    <Download className="w-4 h-4" />
    <span>{label}</span>
  </Button>
);

export default BounceTester;