import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './pages/app';
import './globals.css';

const apiUrl = process.env.NODE_ENV === 'development' 
  ? 'http://localhost:4242' 
  : process.env.REACT_APP_API_URL || 'https://api.lancesjobfinder.com';

const client = new ApolloClient({
  uri: `${apiUrl}/graphql`,
  cache: new InMemoryCache()
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Router>
        <App />
      </Router>
    </ApolloProvider>
  </React.StrictMode>
);