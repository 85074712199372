import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useQuery, useLazyQuery, gql, useMutation } from '@apollo/client';
import { format, subDays, addDays} from 'date-fns';
import { AreaChart, Area, XAxis, YAxis, ResponsiveContainer, Tooltip as RechartsTooltip } from 'recharts';

// Shadcn Imports
import { Button } from "../components/ui/button";
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from "../components/ui/card";
import { useToast } from "../components/ui/use-toast";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../components/ui/table";
import { Pagination, PaginationContent, PaginationEllipsis, PaginationItem, PaginationLink, PaginationNext, PaginationPrevious } from "../components/ui/pagination"
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../components/ui/dialog";
import { ScrollArea } from "../components/ui/scroll-area";
import { Badge } from "../components/ui/badge";

// Lucide Icons
import { BarChart, Activity, Clock, X, Mail, ExternalLink, Calendar, Briefcase, Globe } from 'lucide-react';

// GraphQL Queries
const GET_HISTORICAL_SENDS = gql`
  query GetHistoricalSends($days: Int!, $user_id: Int!) {
    historicalEmailSends(days: $days, user_id: $user_id) {
      date
      count
    }
    scheduledSendsThisWeek
  }
`;

const GET_EMAIL_QUEUE = gql`
  query GetEmailQueue($user_id: Int!, $status: String!, $page: Int!, $pageSize: Int!) {
    emailQueue(user_id: $user_id, status: $status, page: $page, pageSize: $pageSize) {
      totalCount
      emails {
        id
        recipient_email
        recipient_name
        company_name
        company_website
        subject
        message
        scheduled_time
        status
      }
    }
  }
`;

const CANCEL_EMAIL = gql`
  mutation CancelEmail($emailId: ID!) {
    cancelEmail(emailId: $emailId) {
      id
      status
    }
  }
`;

const GET_EMAIL_STATS = gql`
  query GetEmailStats($userId: ID!, $startDate: String, $endDate: String) {
    emailStats(userId: $userId, startDate: $startDate, endDate: $endDate) {
      totalSent
      totalOpened
      openRate
    }
  }
`;

const Dashboard = ({ userId }) => {
  const { toast } = useToast();
  const [currentPage, setCurrentPage] = useState(1);
  const [timeRange, setTimeRange] = useState('30d');
  const pageSize = 5;
  
  const timeRangeToDays = useCallback((range) => {
    switch (range) {
      case '7d': return 7;
      case '30d': return 30;
      case '90d': return 90;
      default: return 30;
    }
  }, []);
  
  const endDate = useMemo(() => new Date().toISOString(), []);
  const startDate = useMemo(() => subDays(new Date(), timeRangeToDays(timeRange)).toISOString(), [timeRange, timeRangeToDays]);

  const [selectedEmail, setSelectedEmail] = useState(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const queryVariables = useMemo(() => ({
    days: timeRangeToDays(timeRange),
    user_id: userId ? parseInt(userId) : null,
    status: 'pending',
    page: currentPage,
    pageSize,
    startDate,
    endDate
  }), [timeRange, timeRangeToDays, userId, currentPage, pageSize, startDate, endDate]);

  const { data: historicalData, loading: historicalLoading } = useQuery(GET_HISTORICAL_SENDS, {
    variables: { days: queryVariables.days, user_id: queryVariables.user_id },
    skip: !userId,
    fetchPolicy: 'cache-and-network',
    pollInterval: 300000,
  });
  
  const { data: emailQueueData, loading: emailQueueLoading, refetch: refetchEmailQueue } = useQuery(GET_EMAIL_QUEUE, {
    variables: { user_id: queryVariables.user_id, status: queryVariables.status, page: queryVariables.page, pageSize: queryVariables.pageSize },
    skip: !userId,
  });

  const { data: emailStatsData, loading: emailStatsLoading } = useQuery(GET_EMAIL_STATS, {
    variables: { 
      userId: queryVariables.user_id, 
      startDate: queryVariables.startDate, 
      endDate: queryVariables.endDate 
    },
    skip: !userId,
    fetchPolicy: 'cache-and-network',
    pollInterval: 300000,
  });

  useEffect(() => {
    if (userId && !emailQueueLoading) {
      refetchEmailQueue();
    }
  }, [currentPage, userId, refetchEmailQueue, emailQueueLoading]);

  const [cancelEmail] = useMutation(CANCEL_EMAIL, {
    onCompleted: () => {
      refetchEmailQueue();
      toast({
        title: "Email Cancelled",
        description: "The scheduled email has been cancelled successfully.",
      });
    },
    onError: (error) => {
      console.error('Error cancelling email:', error);
      toast({
        title: "Error",
        description: "Failed to cancel email. Please try again.",
        variant: "destructive",
      });
    },
  });

  const formatChartData = useCallback((data, range) => {
    const today = new Date();
    const days = timeRangeToDays(range);
    return Array.from({ length: days }, (_, i) => {
      const date = subDays(today, days - 1 - i);
      const dataPoint = data?.find((item) => {
        const itemDate = new Date(parseInt(item.date));
        return itemDate.toDateString() === subDays(date, 1).toDateString();
      });
      return {
        name: format(date, 'MMM d'),
        total: dataPoint ? dataPoint.count : 0,
        timestamp: date.getTime(),
      };
    });
  }, [timeRangeToDays]);


  const chartData = useMemo(() => formatChartData(historicalData?.historicalEmailSends, timeRange), [historicalData, timeRange, formatChartData]);

  const totalEmails = useMemo(() => {
    const total = chartData.reduce((sum, day) => sum + day.total, 0);
    console.log('Total emails:', total);
    return total;
  }, [chartData]);

  const averageEmails = useMemo(() => {
    const avg = totalEmails / timeRangeToDays(timeRange);
    console.log('Average emails:', avg);
    return avg;
  }, [totalEmails, timeRange]);

  const timeSaved = useMemo(() => {
    const time = totalEmails * 20;
    console.log('Time saved:', time);
    return time;
  }, [totalEmails]);

  const handleCancelEmail = useCallback((emailId) => {
    console.log('Cancelling email:', emailId);
    cancelEmail({ 
      variables: { emailId }
    });
  }, [cancelEmail]);

  const handlePageChange = useCallback((newPage) => {
    console.log('Changing page to:', newPage);
    setCurrentPage(newPage);
  }, []);

  const getPageRange = useCallback(() => {
    const totalPages = Math.ceil((emailQueueData?.emailQueue.totalCount || 0) / pageSize);
    console.log('Total pages:', totalPages);
    const range = [];
    const maxVisiblePages = 5;
    const halfVisible = Math.floor(maxVisiblePages / 2);
    
    let start = Math.max(1, currentPage - halfVisible);
    let end = Math.min(totalPages, start + maxVisiblePages - 1);
    
    if (end - start + 1 < maxVisiblePages) {
      start = Math.max(1, end - maxVisiblePages + 1);
    }
    
    for (let i = start; i <= end; i++) {
      range.push(i);
    }
    
    console.log('Page range:', range);
    return range;
  }, [currentPage, emailQueueData, pageSize]);

  console.log('Dashboard component fully initialized');
  
  const SummaryCard = useMemo(() => ({ title, value, icon, subValue, color }) => (
    <Card className="overflow-hidden transition-all duration-300 hover:shadow-md">
        <CardContent className="p-6">
          <div className={`p-2 rounded-full w-12 h-12 flex items-center justify-center ${color} mb-4`}>
            {icon}
          </div>
          <CardTitle className="text-2xl font-bold text-gray-900 mb-1">{value}</CardTitle>
          <p className="text-sm font-medium text-gray-500">{title}</p>
          {subValue && <p className="text-xs text-gray-400 mt-2">{subValue}</p>}
        </CardContent>
      </Card>
  ), []);
  
    const totalPages = Math.ceil((emailQueueData?.emailQueue.totalCount || 0) / pageSize);
  
    return (
      <div className="space-y-8">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
          <h2 className="text-3xl font-bold text-gray-900">Dashboard</h2>
          <div className="flex space-x-2 bg-gray-100 p-1 rounded-lg">
            {['7d', '30d', '90d'].map((range) => (
              <Button
                key={range}
                variant={timeRange === range ? "default" : "ghost"}
                size="sm"
                onClick={() => setTimeRange(range)}
                className={`${timeRange === range ? 'bg-white shadow-sm' : 'hover:bg-gray-200'} transition-all duration-200`}
              >
                {range === '7d' ? '7 days' : range === '30d' ? '30 days' : '90 days'}
              </Button>
            ))}
          </div>
        </div>
  
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
          <SummaryCard
            title="Total Emails Sent"
            value={totalEmails.toLocaleString()}
            icon={<BarChart className="w-6 h-6 text-blue-600" />}
            subValue={`Last ${timeRangeToDays(timeRange)} days`}
            color="bg-blue-100"
          />
          <SummaryCard
            title="Avg. Emails / Day"
            value={averageEmails.toFixed(1)}
            icon={<Activity className="w-6 h-6 text-green-600" />}
            subValue={`Last ${timeRangeToDays(timeRange)} days`}
            color="bg-green-100"
          />
          <SummaryCard
            title="Time Saved"
            value={`${Math.floor(timeSaved / 60)} hrs ${timeSaved % 60} min`}
            icon={<Clock className="w-6 h-6 text-yellow-600" />}
            subValue={`Last ${timeRangeToDays(timeRange)} days`}
            color="bg-yellow-100"
          />
          <SummaryCard
            title="Email Open Rate"
            value={(() => {
              const openRate = emailStatsData?.emailStats.openRate;
              return (openRate && !isNaN(openRate))
                ? `${openRate.toFixed(1)}%`
                : 'N/A';
            })()}
            icon={<Mail className="w-6 h-6 text-purple-600" />}
            subValue={(() => {
              const totalOpened = emailStatsData?.emailStats.totalOpened;
              const totalSent = emailStatsData?.emailStats.totalSent;
              if (totalOpened && totalSent && !isNaN(totalOpened) && !isNaN(totalSent)) {
                return `${totalOpened} / ${totalSent} opened`;
              }
              return 'Data unavailable';
            })()}
            color="bg-purple-100"
          />
      </div>
  
        <Card className="overflow-hidden">
          <CardHeader className="pb-2">
            <CardTitle className="text-xl font-semibold">Email Performance</CardTitle>
          </CardHeader>
          <CardContent className="pt-0">
            <div className="h-[400px]">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={chartData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                  <defs>
                    <linearGradient id="colorTotal" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#3b82f6" stopOpacity={0.8}/>
                      <stop offset="95%" stopColor="#3b82f6" stopOpacity={0}/>
                    </linearGradient>
                  </defs>
                  <XAxis 
                    dataKey="timestamp" 
                    tickFormatter={(value) => format(new Date(value), 'MMM d')}
                    ticks={chartData.filter((_, index) => index % 5 === 0).map(d => d.timestamp)}
                    tickLine={false}
                    axisLine={false}
                    stroke="#888"
                  />
                  <YAxis 
                    allowDecimals={false}
                    tickLine={false}
                    axisLine={false}
                    stroke="#888"
                  />
                  <RechartsTooltip
                    content={({ active, payload }) => {
                      if (active && payload && payload.length) {
                        return (
                          <div className="bg-white p-3 border border-gray-200 rounded-lg shadow-md">
                            <p className="text-sm font-medium text-gray-900">{format(new Date(payload[0].payload.timestamp), 'MMMM d, yyyy')}</p>
                            <p className="text-sm font-bold text-blue-600">{`Total: ${payload[0].value}`}</p>
                          </div>
                        );
                      }
                      return null;
                    }}
                  />
                  <Area 
                    type="monotone" 
                    dataKey="total" 
                    stroke="#3b82f6" 
                    fill="url(#colorTotal)"
                    fillOpacity={0.1}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>
  
        <Card>
        <CardHeader className="pb-2">
          <CardTitle className="text-xl font-semibold flex justify-between items-center">
            <span>Scheduled Activity</span>
          </CardTitle>
        </CardHeader>
        <CardContent className="p-0">
          {emailQueueData?.emailQueue.emails.length > 0 ? (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[25%]">Recipient</TableHead>
                  <TableHead className="w-[30%]">Company</TableHead>
                  <TableHead className="w-[25%]">Scheduled Time</TableHead>
                  <TableHead className="w-[20%]">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {emailQueueData.emailQueue.emails.map((email) => (
                  <TableRow key={email.id} className="hover:bg-gray-50 transition-colors duration-150">
                    <TableCell className="font-medium">
                      {email.recipient_name}
                    </TableCell>
                    <TableCell>
                      {email.company_name}
                      <div className="text-sm text-blue-500 truncate">
                        <a href={email.company_website} target="_blank" rel="noopener noreferrer" className="hover:underline flex items-center">
                          {email.company_website} <ExternalLink className="h-3 w-3 ml-1" />
                        </a>
                      </div>
                    </TableCell>
                    <TableCell>
                      {format(new Date(email.scheduled_time), 'MMM d, h:mm a')}
                    </TableCell>
                    <TableCell>
                      <div className="flex space-x-2">
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => {
                            setSelectedEmail(email);
                            setIsPreviewOpen(true);
                          }}
                          className="hover:bg-blue-100 hover:text-blue-600 transition-colors duration-150"
                        >
                          <Mail className="h-4 w-4" />
                        </Button>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => handleCancelEmail(email.id)}
                          className="hover:bg-red-100 hover:text-red-600 transition-colors duration-150"
                        >
                          <X className="h-4 w-4" />
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <div className="flex flex-col items-center justify-center h-[200px] text-center p-4">
              <Mail className="w-12 h-12 text-gray-400 mb-4" />
              <p className="text-lg font-semibold text-gray-700 mb-2">No scheduled emails</p>
              <p className="text-sm text-gray-500">Start your campaign to see scheduled activity here.</p>
            </div>
          )}
</CardContent>
          {emailQueueData?.emailQueue.emails.length > 0 && (
            <CardFooter className="bg-gray-50 border-t border-gray-100">
              <Pagination>
                <PaginationContent>
                  <PaginationItem>
                    <PaginationPrevious 
                      onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
                      className={currentPage === 1 ? 'pointer-events-none opacity-50' : ''}
                    />
                  </PaginationItem>
                  {getPageRange().map((pageNum) => (
                    <PaginationItem key={pageNum}>
                      <PaginationLink 
                        onClick={() => handlePageChange(pageNum)}
                        isActive={currentPage === pageNum}
                      >
                        {pageNum}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                  <PaginationItem>
                    <PaginationNext 
                      onClick={() => handlePageChange(Math.min(totalPages, currentPage + 1))}
                      className={currentPage === totalPages ? 'pointer-events-none opacity-50' : ''}
                    />
                  </PaginationItem>
                </PaginationContent>
              </Pagination>
            </CardFooter>
          )}
        </Card>
        
        {selectedEmail && (
          <ImprovedEmailPreviewDialog
            email={selectedEmail}
            open={isPreviewOpen}
            onOpenChange={setIsPreviewOpen}
          />
        )}
        
      </div>
    );
};

export default Dashboard;

export const ImprovedEmailPreviewDialog = ({ email, open, onOpenChange }) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px] p-0 overflow-hidden bg-white">
        <DialogHeader className="px-6 py-4 bg-gradient-to-r from-blue-500 to-blue-600">
          <DialogTitle className="text-xl font-semibold text-white">Email Preview</DialogTitle>
        </DialogHeader>
        <div className="px-6 py-4 bg-gray-50 border-b border-gray-200">
          <div>
            <h3 className="text-lg font-semibold text-gray-900">{email.recipient_name}</h3>
            <p className="text-sm text-gray-500">{email.recipient_email}</p>
          </div>
        </div>
        <ScrollArea className="max-h-[60vh]">
          <div className="px-6 py-4 space-y-4">
            <div>
              <h4 className="text-sm font-medium text-gray-500 uppercase">Subject</h4>
              <p className="mt-1 text-lg font-semibold text-gray-900">{email.subject}</p>
            </div>
            <div className="flex flex-wrap gap-4">
              <div className="flex items-center space-x-2">
                <Calendar className="w-5 h-5 text-gray-400" />
                <span className="text-sm text-gray-600">
                  {format(new Date(email.scheduled_time), 'MMMM d, yyyy h:mm a')}
                </span>
              </div>
              <div className="flex items-center space-x-2">
                <Briefcase className="w-5 h-5 text-gray-400" />
                <span className="text-sm text-gray-600">{email.company_name}</span>
              </div>
              <div className="flex items-center space-x-2">
                <Globe className="w-5 h-5 text-gray-400" />
                <a href={email.company_website} target="_blank" rel="noopener noreferrer" className="text-sm text-blue-500 hover:underline">
                  {email.company_website}
                </a>
              </div>
            </div>
            <div>
              <h4 className="text-sm font-medium text-gray-500 uppercase">Message</h4>
              <div className="mt-2 p-4 bg-white border border-gray-200 rounded-lg shadow-sm">
                <p className="text-gray-800 whitespace-pre-wrap">{email.message}</p>
              </div>
            </div>
          </div>
        </ScrollArea>
        <div className="px-6 py-4 bg-gray-50 border-t border-gray-200 flex justify-between items-center">
          <Badge variant="outline" className="text-blue-600 bg-blue-50 border-blue-200">
            Scheduled
          </Badge>
          <button
            onClick={() => onOpenChange(false)}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-200"
          >
            Close
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};