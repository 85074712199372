import * as React from 'react';
import { useState, useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import posthog from 'posthog-js'

// Import Components
import Dashboard from './dashboard';
import EmailCampaign from './campaign-setup';
import TargetAudience from './target-audience';
import Settings from './settings';
import Payment from './payment';
import PrivacyPolicy from './privacy-policy';
import TermsOfService from './terms-of-service';
import Sidebar from '../components/sidebar';
import LandingPage from './landing-page';
import Signup from './signup';
import Signin from './signin';
import SuperAdmin from './super-admin';
import BounceTester from './bounce-tester';


import { Toaster } from "../components/ui/toaster";

import { Route, Routes, useNavigate, Navigate, Outlet, useLocation } from 'react-router-dom';
import '../globals.css';

const GET_CAMPAIGN = gql`
  query GetCampaign($user_id: Int!) {
    getCampaign(user_id: $user_id) {
      id
      status
      subject
      message
      country
      state
      city
      industry
      company_size
      email_preference
    }
  }
`;

const API_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:4242' : process.env.REACT_APP_API_URL;
const isProduction = process.env.NODE_ENV === 'production';

if (isProduction && process.env.POSTHOG_API_KEY) {
  posthog.init(process.env.POSTHOG_API_KEY, {
    api_host: 'https://us.i.posthog.com',
    person_profiles: 'identified_only'
  });
} else if (isProduction) {
  console.warn('PostHog API key is not set. PostHog will not be initialized.');
}

const App: React.FC = () => {
  const [connectedEmail, setConnectedEmail] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [campaign, setCampaign] = useState<any>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const isDevelopment = process.env.NODE_ENV === 'development';

  const isMainDomain = isDevelopment
    ? true 
    : window.location.hostname === 'lancesjobfinder.com' || window.location.hostname === 'www.lancesjobfinder.com';

  const isLandingPage = isMainDomain && location.pathname === '/';

  const { data: campaignData, loading: campaignLoading, refetch: refetchCampaign } = useQuery(GET_CAMPAIGN, {
    variables: { user_id: userId ? parseInt(userId) : null },
    skip: !userId,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (campaignData?.getCampaign) {
      setCampaign(campaignData.getCampaign);
    }
  }, [campaignData]);

  const fetchUserData = async () => {
    const token = localStorage.getItem('token');
  
    if (!token) {
      setConnectedEmail(null);
      setUserId(null);
      return false;
    }
  
    try {
      const response = await fetch(`${API_URL}/api/get-connected-email`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (response.ok) {
        const data = await response.json();
        setConnectedEmail(data.email);
        setUserId(data.userId);
        return true;
      } else {
        const errorData = await response.json();
  
        if (response.status === 401 || response.status === 403) {
          if (errorData.error === 'Payment required') {
            // Instead of navigating, return a special value
            return 'payment_required';
          } else if (errorData.error && errorData.error.includes('Google authentication expired')) {
            console.error('Google authentication expired. Please reconnect your account.');
          } else {
            handleLogout();
          }
        } else {
          console.error('Failed to fetch user data:', errorData.error);
          setConnectedEmail(null);
          setUserId(null);
        }
        return false;
      }
    } catch (error) {
      console.error('Failed to fetch user data:', error);
      setConnectedEmail(null);
      setUserId(null);
      return false;
    }
  };

  useEffect(() => {
    const checkAuthentication = async () => {
      const publicRoutes = ['/', '/signup', '/signin', '/privacy-policy', '/terms-of-service'];
      const protectedRoutes = ['/payment', '/settings', '/dashboard', '/email-campaign', '/target-companies'];
  
      if (!publicRoutes.includes(location.pathname)) {
        const authResult = await fetchUserData();
        if (authResult === 'payment_required') {
          navigate('/payment');
        } else if (!authResult && protectedRoutes.includes(location.pathname)) {
          navigate('/signin');
        }
      }
    };
  
    checkAuthentication();
  }, [location.pathname, navigate]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    setConnectedEmail(null);
    setUserId(null);
    navigate('/signin');
  };

  const checkPaymentStatus = async (token: string) => {
    try {
      const response = await fetch(`${API_URL}/api/check-payment-status`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        return data.active;
      } else {
        console.error('Failed to check payment status. Status:', response.status);
        return false;
      }
    } catch (error) {
      console.error('Error checking payment status:', error);
      return false;
    }
  };

  const AuthenticatedRoutes = () => (
    <div className="flex h-screen overflow-hidden bg-gray-50">
      <Sidebar userId={userId} onLogout={handleLogout} />
      <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-50">
        <div className="p-6">
          <Outlet />
        </div>
      </main>
    </div>
  );

  return (
    <>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/super-admin" element={<SuperAdmin />} />
        <Route path="/bounce-tester" element={<BounceTester />} />
        
        {/* Authenticated routes with sidebar */}
        <Route element={<AuthenticatedRoutes />}>
          <Route path="/dashboard" element={<Dashboard userId={userId} />} />
          <Route path="/email-campaign" element={<EmailCampaign userId={userId} />} />
          <Route path="/target-companies" element={<TargetAudience userId={userId} />} />
          <Route path="/settings" element={<Settings setConnectedEmail={setConnectedEmail} onLogout={handleLogout} userId={userId} />} />
        </Route>

        {/* Redirect other paths to dashboard for authenticated users, or landing page for non-authenticated */}
        <Route path="*" element={
          <Navigate to={userId ? "/dashboard" : "/"} replace />
        } />
      </Routes>
      <Toaster />
    </>
  );
};

export default App;