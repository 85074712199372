import React, { useEffect, useCallback, useRef, useState } from 'react';
import Link from 'next/link';
import { ArrowRight, Check, Users, Play, CheckCircle, Zap, TrendingUp, ClockIcon, RocketIcon, Clock, Calendar } from 'lucide-react';
import { Button } from "../components/ui/button";
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from "../components/ui/card";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../components/ui/accordion"
import Navbar from '../components/navbar';
import Pricing from '../components/pricing';
import Footer from '../components/footer';
import TestimonialsSection from '../components/testimonials';

const DemoVideo = require('../assets/videos/demo.mp4');
const Thumbnail = require('../assets/images/thumbnail.png');

const isProd = process.env.NODE_ENV === 'production';
const signupLink = isProd ? 'https://app.lancesjobfinder.com/signup' : 'http://localhost:4242/signup';

const EnhancedVideoCard = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };

  return (
    <div className="bg-white rounded-2xl shadow-2xl overflow-hidden w-full max-w-5xl mx-auto">
      <div className="relative aspect-w-16 aspect-h-9">
        <video
          ref={videoRef}
          src={DemoVideo}
          className="w-full h-full object-cover"
          poster={Thumbnail}
          controls={isPlaying}
        >
          Your browser does not support the video tag.
        </video>
        {!isPlaying && (
          <div 
            className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-30 transition-opacity duration-300 hover:bg-opacity-50"
            style={{
              backgroundImage: `url(${Thumbnail})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <div className="absolute inset-0 bg-black bg-opacity-30 transition-opacity duration-300 hover:bg-opacity-50"></div>
            <Button 
              onClick={handlePlay}
              className="bg-white text-blue-600 hover:bg-blue-50 flex items-center space-x-3 px-6 py-3 sm:px-8 sm:py-4 rounded-full text-lg sm:text-xl font-semibold transition-all duration-300 transform hover:scale-105 shadow-lg relative z-10"
            >
              <Play className="w-6 h-6 sm:w-8 sm:h-8" />
              <span>Watch Demo</span>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};


const LandingPage = () => {
  const scrollTo = useCallback((id) => {
    const element = document.getElementById(id);
    if (element) {
      
      const navbar = document.querySelector('nav');
      const navbarHeight = navbar ? navbar.offsetHeight : 0;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - navbarHeight;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
      setTimeout(() => {
        if (Math.abs(window.pageYOffset - offsetPosition) > 1) {
          window.scrollTo(0, offsetPosition);
        }
      }, 500);
    } else {
      setTimeout(() => {
        const delayedElement = document.getElementById(id);
        if (delayedElement) {
          scrollTo(id);  // Recursively call scrollTo
        } else {
        }
      }, 100);
    }
  }, []);
  
  useEffect(() => {
    if (window.location.hash) {
      const id = window.location.hash.substring(1);
      scrollTo(id);
    }
  }, [scrollTo]);

    const audiences = [
      {
        icon: RocketIcon,
        title: "Urgent Job Seekers",
        description: "For those looking to accelerate their job application process",
        benefit: "Bypass traditional application portals and secure interviews in days"
      },
      {
        icon: ClockIcon,
        title: "Time-Strapped Professionals",
        description: "For those balancing finding a job with current responsibilities",
        benefit: "Automated outreach saving 20+ hours per week on applications"
      },
      {
        icon: Users,
        title: "Exhausted Applicants",
        description: "For those looking to apply to jobs without manually sending 100s of applications",
        benefit: "Direct line to CEOs, opening doors to hidden opportunities"
      }
    ];

  return (
    <div className="min-h-screen bg-white text-gray-900">
      {/* Navigation */}
      <Navbar scrollTo={scrollTo} />

      {/* Hero Section */}
      <header id="hero" className="min-h-screen flex flex-col justify-center items-center pt-16 bg-gradient-to-br from-gray-50 via-white to-gray-100 px-4 sm:px-6">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-4xl sm:text-5xl lg:text-7xl font-bold tracking-tight mb-6 leading-tight text-gray-900">
            Automate Your Way to <br className="hidden sm:inline" />Your Dream Job
          </h1>
          <p className="text-lg sm:text-xl mb-8 text-gray-600 leading-relaxed max-w-2xl mx-auto">
            Lance's Job Finder sends emails to CEOs on your behalf. Secure interviews effortlessly and focus on what matters most - preparing to impress.
          </p>
          <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4 mb-8 sm:mb-12">
            <Button size="lg" className="w-full sm:w-auto bg-blue-600 text-white hover:bg-blue-700 text-lg px-6 py-3 sm:px-8 sm:py-4 rounded-full shadow-lg hover:shadow-xl transition-all duration-300" asChild>
              <Link href={signupLink}>
                Get started
                <ArrowRight className="ml-2" />
              </Link>
            </Button>
          </div>
          <p className="text-sm font-medium text-blue-600 mb-12 sm:mb-16">30-day money-back guarantee</p>
        </div>
        <div className="w-full max-w-3xl mx-auto">
          <EnhancedVideoCard />
        </div>
      </header>


      {/* Stats Section */}
      <section className="py-16 sm:py-24 bg-gradient-to-br from-blue-50 to-indigo-100 relative overflow-hidden">
        <div className="absolute inset-0 bg-white bg-opacity-40"></div>
        <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2MCIgaGVpZ2h0PSI2MCI+CiAgPHBhdGggZD0iTTAgMGg2MHY2MEgweiIgZmlsbD0ibm9uZSIvPgogIDxwYXRoIGQ9Ik0zMCAzMG0tMjguNSwwYTI4LjUsMjguNSAwIDEsMCA1NywwYTI4LjUsMjguNSAwIDEsMCAtNTcsMFoiIHN0cm9rZT0iIzIwNWZkZiIgc3Ryb2tlLW9wYWNpdHk9IjAuMDUiIGZpbGw9Im5vbmUiLz4KPC9zdmc+')] opacity-30"></div>
        <div className="absolute inset-0 bg-gradient-to-br from-blue-400 to-indigo-400 opacity-10"></div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="text-center mb-12 sm:mb-16">
            <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-gray-900">Efficiency Meets Results</h2>
            <p className="mt-4 text-lg sm:text-xl text-gray-600">See how Lance's Job Finder speeds up your job search</p>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              {
                icon: Clock,
                title: "Time Saved",
                value: "20+ hours",
                description: "Per week on job applications",
                color: "blue"
              },
              {
                icon: Users,
                title: "CEO Network",
                value: "500,000+",
                description: "Direct access to decision-makers",
                color: "green"
              },
              {
                icon: TrendingUp,
                title: "Success Rate",
                value: "85%",
                description: "Users securing interviews",
                color: "purple"
              },
              {
                icon: Calendar,
                title: "Average Time to Interview",
                value: "1-2 weeks",
                description: "From signup to first interview",
                color: "red"
              }
            ].map((stat, index) => (
              <div key={index} className="relative group">
                <div className="absolute inset-0 bg-gradient-to-r from-white to-gray-100 rounded-xl transform transition-transform duration-300 group-hover:scale-105"></div>
                <div className="relative p-6 bg-white rounded-xl shadow-lg transition-all duration-300 group-hover:shadow-xl">
                  <div className={`w-12 h-12 mb-4 rounded-full bg-${stat.color}-100 flex items-center justify-center`}>
                    <stat.icon className={`w-6 h-6 text-${stat.color}-600`} />
                  </div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-2">{stat.title}</h3>
                  <div className="text-2xl sm:text-3xl font-bold text-gray-900 mb-2">{stat.value}</div>
                  <p className="text-sm text-gray-600">{stat.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Audience Section */}
      <section className="py-16 sm:py-24 bg-gradient-to-br from-gray-50 to-white">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center text-gray-900 mb-8">
            Who Benefits from Lance's Job Finder?
          </h2>
          <Card className="overflow-hidden shadow-lg">
            <CardHeader className="bg-blue-50 border-b border-blue-100">
              <CardTitle className="text-xl text-blue-900">Perfect For:</CardTitle>
            </CardHeader>
            <CardContent className="p-0">
              <div className="grid sm:grid-cols-2 lg:grid-cols-3 divide-y sm:divide-y-0 sm:divide-x divide-gray-200">
                {audiences.map((audience, index) => (
                  <div key={index} className="p-6 flex flex-col justify-between h-full">
                    <div>
                      <audience.icon className="w-10 h-10 text-blue-500 mb-4" />
                      <h3 className="text-lg font-semibold text-gray-900 mb-2">{audience.title}</h3>
                      <p className="text-sm text-gray-600 mb-4">{audience.description}</p>
                    </div>
                    <div className="mt-4 pt-4 border-t border-gray-100">
                      <p className="text-sm font-medium text-blue-600">Key Benefit:</p>
                      <p className="text-sm text-gray-700">{audience.benefit}</p>
                    </div>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        </div>
      </section>


      {/* Pricing Section */}
      <Pricing />

      {/* Testimonials Section */}
      <TestimonialsSection />

      {/* FAQ Section */}
      <section className="py-16 sm:py-24 bg-gradient-to-br from-white to-gray-50" id="faq">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl sm:text-4xl font-bold text-center text-gray-900 mb-8 sm:mb-12">Your questions, answered.</h2>
          <Accordion type="single" collapsible className="space-y-4">
            {[
              {
                question: "How does Lance's Job Finder work?",
                answer: "Lance's Job Finder sends emails from your Gmail account to CEOs to book you interview phone calls with your target companies."
              },
              {
                question: "How much effort does it require?",
                answer: "You can set up your account in a few minutes. All you need to do is select your target audience and customize one of our email templates to your liking. After that, you'll just take interview phone calls as they come in."
              },
              {
                question: "Can I customize the emails sent on my behalf?",
                answer: "Yes, you have full control over the content of your outreach emails. We provide you with customizable templates that you can edit to your liking."
              },
              {
                question: "What kinds of companies can I target?",
                answer: "You can target companies in the US, UK, Canada and Australia. Lance's Job Finder works best for targeting companies with 10-200 employees."
              },
              {
                question: "What if I'm not satisfied with the service?",
                answer: "We offer a 30-day money-back guarantee. If you're not happy with Lance's Job Finder for any reason, just let us know and we'll refund your subscription."
              }
            ].map((faq, index) => (
              <AccordionItem key={index} value={`item-${index}`} className="border rounded-lg overflow-hidden">
                <AccordionTrigger className="text-left text-base sm:text-lg font-medium px-4 sm:px-6 py-3 sm:py-4 hover:bg-gray-50 transition-colors duration-300">
                  {faq.question}
                </AccordionTrigger>
                <AccordionContent className="text-gray-600 px-4 sm:px-6 py-3 sm:py-4 bg-white text-sm sm:text-base">
                  {faq.answer}
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="py-16 sm:py-24 bg-gradient-to-br from-blue-600 to-purple-600 relative overflow-hidden">
        <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiPgogIDxkZWZzPgogICAgPHBhdHRlcm4gaWQ9InBhdHRlcm4iIHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiBwYXR0ZXJuVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBwYXR0ZXJuVHJhbnNmb3JtPSJyb3RhdGUoNDUgNTAgNTApIj4KICAgICAgPGNpcmNsZSBjeD0iNTAiIGN5PSI1MCIgcj0iMC41IiBmaWxsPSJyZ2JhKDI1NSwyNTUsMjU1LDAuMDUpIiAvPgogICAgPC9wYXR0ZXJuPgogIDwvZGVmcz4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI3BhdHRlcm4pIiAvPgo8L3N2Zz4=')] opacity-20"></div>
        <Card className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 bg-white bg-opacity-10 backdrop-blur-lg relative z-10">
          <div className="py-8 sm:py-12 flex flex-col lg:flex-row items-center justify-between">
            <div className="lg:w-1/2 mb-8 lg:mb-0 lg:pr-12">
              <h2 className="text-3xl sm:text-4xl font-bold mb-4 text-white leading-tight">Ready to transform <br className="hidden sm:inline" />your job search?</h2>
              <Button size="lg" className="w-full sm:w-auto bg-white text-blue-600 hover:bg-blue-50 text-base sm:text-lg px-6 sm:px-8 py-3 sm:py-4 rounded-full shadow-lg hover:shadow-xl transition-all duration-300 transform hover:scale-105 group" asChild>
                <Link href={signupLink}>
                  Get started
                  <ArrowRight className="ml-2 transition-transform group-hover:translate-x-1" />
                </Link>
              </Button>
              <p className="mt-4 text-sm text-blue-200">30-day money-back guarantee</p>
            </div>
            <div className="lg:w-1/2 space-y-6">
              <div className="flex items-start space-x-4">
                <CheckCircle className="w-6 h-6 text-green-400 flex-shrink-0 mt-1" />
                <div>
                  <h3 className="text-lg font-semibold text-white">Automated Outreach</h3>
                  <p className="text-blue-100">Save time with personalized emails sent to CEOs on your behalf.</p>
                </div>
              </div>
              <div className="flex items-start space-x-4">
                <Zap className="w-6 h-6 text-yellow-400 flex-shrink-0 mt-1" />
                <div>
                  <h3 className="text-lg font-semibold text-white">Fast Results</h3>
                  <p className="text-blue-100">Many users secure interviews within the first two weeks.</p>
                </div>
              </div>
              <div className="flex items-start space-x-4">
                <Users className="w-6 h-6 text-purple-400 flex-shrink-0 mt-1" />
                <div>
                  <h3 className="text-lg font-semibold text-white">500,000+ CEO Network</h3>
                  <p className="text-blue-100">Bypass traditional applications and speak directly with the decision maker.</p>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </section>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default LandingPage;