import React from 'react';
import { Link } from 'react-router-dom';
import { Origami } from 'lucide-react';

const Footer: React.FC = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <footer className="bg-gray-900 text-white py-12 sm:py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col items-center sm:items-start sm:flex-row justify-between">
          <div className="mb-8 sm:mb-0 text-center sm:text-left">
            <div className="flex items-center justify-center sm:justify-start mb-4">
              <Origami className="h-8 w-8 text-blue-400 mr-2" />
              <span className="text-2xl font-bold">Lance's Job Finder</span>
            </div>
            <p className="text-gray-400 max-w-xs mx-auto sm:mx-0">Automating outreach so you can find and land your dream job.</p>
          </div>
          <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-6">
            <Link 
              to="/terms-of-service" 
              className="text-gray-400 hover:text-white transition-colors duration-300"
              onClick={scrollToTop}
            >
              Terms of Service
            </Link>
            <Link 
              to="/privacy-policy" 
              className="text-gray-400 hover:text-white transition-colors duration-300"
              onClick={scrollToTop}
            >
              Privacy Policy
            </Link>
            <a href="#" className="text-gray-400 hover:text-white transition-colors duration-300">
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;